import { useTranslation } from "react-i18next";

import * as Presenter from "./NoteModal.presenter";
import { NoteModalData, NoteModalForm } from "./NoteModalForm";

export type NoteModalState = NoteModalData | null;

interface Props {
  data: NoteModalState;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (data: { timecardId: string; note: string }) => void;
}

export const NoteModal = ({ data, isSubmitting, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const noteContext = data !== null && data.note === null ? "add" : "edit";

  return (
    <Presenter.EditNotesCustomModal
      visible={data !== null}
      title={t("components.noteModal.title", {
        context: noteContext,
        workerName: data?.workerName,
      })}
      body={
        data === null ? null : (
          <NoteModalForm
            data={data}
            isSubmitting={isSubmitting}
            onSubmit={(note) => onSubmit({ timecardId: data.timecardId, note })}
          />
        )
      }
      onClose={onClose}
    />
  );
};
