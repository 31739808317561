import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { User } from "@/types/User";

import { fetchCurrentUser } from "../actions/session.actions";
import { fetchListData } from "../actions/shifts.actions";

export interface UsersState extends EntityState<User, string> {}

const usersAdapter = createEntityAdapter<User>();

export const initialState: UsersState = usersAdapter.getInitialState({});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
        usersAdapter.upsertOne(state, payload);
      })
      .addCase(fetchListData.fulfilled, (state, { payload }) => {
        usersAdapter.upsertMany(state, payload.users);
      });
  },
});

export const usersAdapterSelectors = usersAdapter.getSelectors();

export const { reducer: usersReducer } = usersSlice;
