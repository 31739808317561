import { MediumModal, mq, rem } from "@nowsta/tempo-ds";
import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  > button {
    width: 100%;
  }

  textarea {
    // Tall enough for 10 lines of text
    min-height: ${rem(230)};

    ${mq.whileTouch} {
      min-height: ${rem(260)};
    }
  }
`;

export const EditNotesCustomModal = styled(MediumModal)`
  > div {
    > div {
      > div:nth-child(2) {
        > div {
          padding-bottom: ${rem(32)};
        }
      }
    }
  }
`;
