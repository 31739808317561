import { createAsyncThunk } from "@reduxjs/toolkit";

import { companyService } from "@/services/company";
import { jobService } from "@/services/job";
import { userService } from "@/services/user";
import { Company } from "@/types/Company";
import { User } from "@/types/User";
import { FetchWorkerShiftsQueryParams, WorkerShift } from "@/types/WorkerShift";

export interface DecoratedWorkerShift extends WorkerShift {
  id: string;
  worker: {
    id: string;
    name: string;
    imgUrl?: string;
  };
  company: {
    id: string;
    name: string;
    imgUrl?: string;
  };
}

export interface FetchShiftsPayload {
  users: User[];
  companies: Company[];
  shifts: DecoratedWorkerShift[];
}

const decorateShift = ({
  fetchedCompanies,
  fetchedUsers,
  shifts,
  stateCompanies,
  stateUsers,
}: {
  stateUsers: Record<string, User>;
  fetchedUsers: User[];
  stateCompanies: Record<string, Company>;
  fetchedCompanies: Company[];
  shifts: WorkerShift[];
}): DecoratedWorkerShift[] => {
  const mappedUsers = fetchedUsers.reduce<Record<string, User>>(
    (record, u) => ({ ...record, [u.id]: u }),
    {}
  );
  const mappedCompanies = fetchedCompanies.reduce<Record<string, Company>>(
    (record, u) => ({ ...record, [u.id]: u }),
    {}
  );

  return shifts.map((s) => {
    const company =
      mappedCompanies[s.applicationCompanyId] ||
      stateCompanies[s.applicationCompanyId];
    const user = mappedUsers[s.applicantId] || stateUsers[s.applicantId];

    return {
      ...s,
      id: s.timecardId,
      worker: user
        ? {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            imgUrl: user.avatarUrl,
          }
        : {
            id: s.applicantId,
            name: "Worker",
          },
      company: company
        ? {
            id: company.id,
            name: company.name,
            imgUrl: company.logoUrl,
          }
        : {
            id: s.applicationCompanyId,
            name: "Company",
          },
    };
  });
};

const fetchIfNotEmpty = async <T>(
  request: (body: { requestBody: { ids: string[] } }) => Promise<T[]>,
  ids: string[]
) => {
  if (ids.length > 0) {
    return request({ requestBody: { ids } });
  }

  return [] as T[];
};

export const fetchListData = createAsyncThunk<
  FetchShiftsPayload,
  Omit<FetchWorkerShiftsQueryParams, "timeZone">
>("combined/shifts-workers-companies", async (params, { getState }) => {
  const {
    companies: { entities: companyEntities },
    users: { entities: userEntities },
  } = getState() as {
    // Manually definiting this to avoid dependency cycles
    companies: { entities: Record<string, Company> };
    users: { entities: Record<string, User> };
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { data: shifts } = await jobService.getConfirmedShifts({
    ...params,
    timeZone,
  });

  const { companyIds, userIds } = shifts.reduce<
    Record<"companyIds" | "userIds", Partial<Record<string, true>>>
  >(
    (ids, s) => {
      const user =
        userEntities[s.applicantId] || ids.userIds[s.applicantId]
          ? null
          : s.applicantId;
      const company =
        companyEntities[s.applicationCompanyId] ||
        ids.companyIds[s.applicationCompanyId]
          ? null
          : s.applicationCompanyId;

      if (!user && !company) {
        return ids;
      }

      return {
        companyIds: company
          ? { ...ids.companyIds, [company]: true }
          : ids.companyIds,
        userIds: user ? { ...ids.userIds, [user]: true } : ids.userIds,
      };
    },
    { companyIds: {}, userIds: {} }
  );

  const [companies, users] = await Promise.all([
    fetchIfNotEmpty(companyService.getBatchCompanies, Object.keys(companyIds)),
    fetchIfNotEmpty(userService.getBatchWorkers, Object.keys(userIds)),
  ]);

  return {
    companies,
    users,
    shifts: decorateShift({
      fetchedCompanies: companies,
      fetchedUsers: users,
      shifts,
      stateCompanies: companyEntities,
      stateUsers: userEntities,
    }),
  };
});
