import { isMobile } from "react-device-detect";
import {
  getCookie,
  removeCookie,
  setCookie as setTypescriptCookie,
} from "typescript-cookie";

const ACCESS_TOKEN = "n2-token";
const REFRESH_TOKEN = "n2-refresh";
const MOBILE_COOKIE_EXPIRATION_IN_DAYS = 7;

const setCookie = (name: string, value: string) => {
  if (isMobile) {
    setTypescriptCookie(name, value, {
      expires: MOBILE_COOKIE_EXPIRATION_IN_DAYS,
    });
  } else {
    setTypescriptCookie(name, value);
  }
};

export const setAccessToken = (token: string) => setCookie(ACCESS_TOKEN, token);
export const getAccessToken = () => getCookie(ACCESS_TOKEN);
export const removeAccessToken = () => removeCookie(ACCESS_TOKEN);

export const setRefreshToken = (token: string) =>
  setCookie(REFRESH_TOKEN, token);
export const getRefreshToken = () => getCookie(REFRESH_TOKEN);
export const removeRefreshToken = () => removeCookie(REFRESH_TOKEN);

export const logOut = () => {
  removeAccessToken();
  removeRefreshToken();
};
