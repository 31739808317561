import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { authService } from "@/services/auth";
import { jobService } from "@/services/job";
import { userService } from "@/services/user";
import { SignIn, SignInResponse } from "@/types/Auth";
import { Company, FetchClientsQueryParams } from "@/types/Company";
import { User } from "@/types/User";

export const initSession = createAction("session/initSession");

export const logout = createAction("session/logout");

export const setSelectedCompany = createAction<{ companyId: string | null }>(
  "session/setSelectedCompany"
);

export const setSelectedDate = createAction<{ selectedDate: string | null }>(
  "session/setSelectedDate"
);

export const login = createAsyncThunk<SignInResponse, SignIn>(
  "session/login",
  async (payload) => {
    const data = await authService.signIn({ requestBody: payload });

    return data;
  }
);

interface FetchUserCompaniesPayload {
  companies: Company[];
  date: string | null;
}

export const fetchUserCompanies = createAsyncThunk<
  FetchUserCompaniesPayload,
  Omit<FetchClientsQueryParams, "timeZone">
>("session/fetchMyCompanies", async ({ date }) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return {
    companies: await jobService.fetchClients({ timeZone, date }),
    date: date || null,
  };
});

export const fetchCurrentUser = createAsyncThunk<User>(
  "session/fetchCurrentUser",
  async () => await userService.getCurrentUser()
);
