import { InitOptions } from "i18next";

export const baseConfig: InitOptions = {
  fallbackLng: "en-us",
  load: "currentOnly",
  lowerCaseLng: true,
  debug: true,
  ns: ["common"],
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
};
