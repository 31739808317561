import { ApiFunction } from "@/types/Api";
import { GetBatchWorkersRequestBody, User } from "@/types/User";

import { createDefaultClient, createPublicClient } from "./shared";

export const userClient = createDefaultClient("user-service");
export const userPublicClient = createPublicClient("user-service/auth");

export interface ToggleWorkerResponse {
  companyId: string;
  workerId: string;
  isActive: boolean;
}

export interface CoordinatorByCompanyParams {
  companyId: string;
  invitationStatus?: string;
  searchQuery?: string;
}

export interface CoordinatorRemoveParams {
  coordinatorId: string;
  companyId: string;
}

export const USER_SERVICE_PATHS = {
  getCurrentUser: "/users/current",
  getBatchWorkers: "/workers/batch-workers",
};

export type UserService = {
  getCurrentUser: ApiFunction<User>;
  getBatchWorkers: ApiFunction<User[], GetBatchWorkersRequestBody>;
};

export const userService: UserService = {
  getCurrentUser: async () => {
    const { data } = await userClient.get(USER_SERVICE_PATHS.getCurrentUser);

    return data;
  },
  getBatchWorkers: async ({ requestBody }) => {
    const { data } = await userClient.post(
      USER_SERVICE_PATHS.getBatchWorkers,
      requestBody
    );

    return data;
  },
};
