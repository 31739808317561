import {
  BoxUIProps,
  SpaceUIProps,
  color,
  hoverFocusActiveStyles,
  hoverFocusStateHelper,
  rem,
  resetHelper,
  shadowAsBorderHelper,
  textSize,
  textWeight,
  transitionHelper,
} from "@nowsta/tempo-ds";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(RouterLink)`
  ${resetHelper}
`;

export const Wrapper = styled.li`
  ${resetHelper}

  position: relative;
  border-radius: ${rem(10)};

  box-shadow: ${shadowAsBorderHelper(1, color.ui20)};

  transition: ${transitionHelper("box-shadow")};

  ${hoverFocusStateHelper(false, false, "ui", "both")}
  :before {
    border-radius: ${rem(10)};
  }

  :hover,
  :focus-within {
    box-shadow: ${shadowAsBorderHelper(1, color.ui60)};

    ${hoverFocusActiveStyles}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  padding: ${rem(16)};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(32)};
`;

export const TextGroup = styled.div<BoxUIProps & SpaceUIProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
`;

export const Heading = styled.div`
  ${textSize.headlineS}
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};
`;

export const DemoBadge = styled.div`
  position: absolute;
  right: ${rem(8)};
  top: ${rem(8)};
`;
