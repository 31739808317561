// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeRefs<T = any>(
  ...refs: Array<React.MutableRefObject<T> | React.Ref<T> | undefined>
) {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  return (node: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = node;
      }
    }
  };
}
