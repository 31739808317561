import {
  color,
  rem,
  resetHelper,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  overflow: auto;

  header {
    position: sticky;
    top: 0;

    display: flex;
    gap: ${rem(16)};
    justify-content: flex-end;
    align-items: center;
    padding: 0 ${rem(8)};
  }
`;

export const SearchInput = styled.div`
  margin-bottom: ${rem(16)};
`;

export const UserDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(8)};

  padding: ${rem(8)};
  > span {
    ${textSize.labelM}
    font-weight: ${textWeight.medium};
  }
`;

export const ContentBox = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${rem(16)};
`;

export const CompanyList = styled.ul`
  ${resetHelper}

  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`;

export const ContentHeader = styled.h1`
  padding: ${rem(16)};
  ${textSize.titleM}
  font-weight: ${textWeight.bold};
  color: ${color.ui160};
`;

export const Text = styled.p`
  ${textSize.bodyXL}
  font-weight: ${textWeight.regular};
  color: ${color.ui111};
  padding: ${rem(16)};
`;
