import { color, rem, reserved, textSize, textWeight } from "@nowsta/tempo-ds";
import styled from "styled-components";

import { PHONE_QUERY } from "@/helpers/breakpoints";

export const Wrapper = styled.div`
  position: relative;
  height: ${rem(64)};
  width: ${rem(64)};

  ${PHONE_QUERY.start} {
    height: ${rem(48)};
    width: ${rem(48)};
  }
`;

export const MainImage = styled.div`
  width: 100%;
  height: 100%;
  border: ${rem(1)} solid ${color.ui10};
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
  }

  > div {
    ${textSize.labelL};
    font-weight: ${textWeight.semiBold};
    color: ${color.ui122};
    background-color: ${color.ui20};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const SourceImage = styled.div`
  position: absolute;
  height: ${rem(24)};
  width: ${rem(24)};
  border-radius: ${rem(reserved.corners.medium)};
  overflow: hidden;
  right: ${rem(-5)};
  bottom: ${rem(-4)};

  > img {
    width: 100%;
    height: 100%;
  }

  > div {
    ${textSize.labelS};
    font-weight: ${textWeight.semiBold};
    color: ${color.ui122};
    background-color: ${color.ui10};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  ${PHONE_QUERY.start} {
    height: ${rem(16)};
    width: ${rem(16)};
    right: 0;
    bottom: 0;

    > div {
      ${textSize.labelXS};
    }
  }
`;
