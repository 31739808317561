import {
  addOpacityToHex,
  color,
  mq,
  rem,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: ${rem(360)};
  display: flex;
  ${mq.untilLaptop.start} {
    flex-direction: column;
  }
`;

const loginBackgroundUrl =
  "https://nowsta-assets.s3.amazonaws.com/bridge-min.jpg";

export const PageBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${rem(16)};

  height: ${rem(140)};
  max-width: 100%;

  background-color: ${addOpacityToHex(color.primary100, 0.9)};
  background-image: url(${loginBackgroundUrl});
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;

  span {
    ${textSize.titleS}
    font-weight: ${textWeight.black};
    color: white;
  }

  .icon__nowstaLogo {
    width: ${rem(32)};
    height: ${rem(32)};
  }

  ${mq.fromTablet} {
    flex-basis: 50%;
    max-width: 50%;
    height: auto;

    .icon__nowstaLogo {
      width: ${rem(48)};
      height: ${rem(48)};
    }
  }

  ${mq.untilLaptop.start} {
    max-width: 100%;
    flex-basis: unset;
    height: ${rem(180)};

    ${textSize.headlineL}
  }
`;

export const PageContent = styled.main`
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  ${mq.fromTablet} {
    flex-basis: 50%;
    max-width: 50%;
  }
  ${mq.untilLaptop.start} {
    max-width: 100%;
  }
`;

export const Fineprint = styled.footer`
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};
  align-items: center;

  margin: ${rem(16)} ${rem(24)};

  text-align: center;
  ${textSize.bodyS}
  font-weight: ${textWeight.medium};
  color: ${color.ui100};

  p {
    display: flex;
    gap: ${rem(8)};
  }
  a {
    color: ${color.ui100};
  }
`;

export const Separator = styled.span`
  color: ${color.ui60};
`;

export const ContentBox = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogInContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  width: ${rem(360)};
`;

export const LogInHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};

  h1 {
    ${textSize.titleL}
    font-weight: ${textWeight.black};
    color: ${color.primary100};
  }

  p {
    ${textSize.headlineS}
    font-weight: ${textWeight.regular};
    color: ${color.ui111};
  }
`;
