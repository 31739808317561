import { ErrorBoundary, GlobalPreset, PageNotFound } from "@nowsta/tempo-ds";
import "@nowsta/tempo-ds/dist/assets/fonts.css";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import "@/i18n/i18next";

import { TopLevelErrorBoundary } from "./TopLevelErrorBoundary";
import { GenericLoadingScreen } from "./components/GenericLoadingScreen";
import { GlobalStyles } from "./components/GlobalStyles";
import { AuthGuard } from "./components/Guards/AuthGuard";
import { CompanyGuard } from "./components/Guards/CompanyGuard";
import { InitGuard } from "./components/Guards/InitGuard";
import { CaptainLayout } from "./components/Layouts/CaptainLayout";
import { CompanySelector } from "./pages/CompanySelector";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { Root } from "./pages/Root";
import { Workers } from "./pages/Workers";
import { setUpStore } from "./redux/store";
import { PATHS } from "./routing/paths";

const store = setUpStore();

const router = createBrowserRouter([
  {
    path: PATHS.root,
    errorElement: <ErrorBoundary />,
    element: <InitGuard component={Outlet} />,
    children: [
      {
        path: PATHS.root,
        element: <AuthGuard component={Outlet} />,
        children: [
          {
            index: true,
            element: <Root />,
          },
          {
            path: PATHS.companySelector,
            element: <CompanySelector />,
          },
          {
            path: PATHS.companyId.root,
            element: <CompanyGuard component={CaptainLayout} />,
            children: [
              {
                index: true,
                element: <Workers />,
              },
              {
                path: PATHS.companyId.date,
                element: <Workers />,
              },
            ],
          },
        ],
      },
      {
        path: PATHS.login,
        element: <Login />,
      },
      {
        path: PATHS.logout,
        element: <Logout />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <TopLevelErrorBoundary>
      <Suspense fallback={<GenericLoadingScreen />}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
        <GlobalPreset />
        <GlobalStyles />
      </Suspense>
    </TopLevelErrorBoundary>
  );
};
