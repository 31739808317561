import { useTranslation } from "react-i18next";

import { LoadingMessages } from "../LoadingMessages";
import * as Presenter from "./GenericLoadingScreen.presenter";

// From: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle(array: string[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const GenericLoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <Presenter.Main>
      <LoadingMessages
        messages={shuffle(
          t("components.loadingMessages.appMessages", {
            returnObjects: true,
          }) as string[]
        )}
      />
    </Presenter.Main>
  );
};
