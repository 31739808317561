import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const DEFAULT_POLL_TIME = 60;

export const usePollTime = () => {
  const [params] = useSearchParams();
  const pollTime = params.get("p");

  return useMemo(() => {
    if (!pollTime) {
      return DEFAULT_POLL_TIME;
    }

    const sanitized = Number(pollTime);

    if (isNaN(sanitized) || sanitized < 5) {
      return DEFAULT_POLL_TIME;
    }

    return sanitized;
  }, [pollTime]);
};
