import { useCallback } from "react";

import { FetchWorkerShiftsQueryParams } from "@/types/WorkerShift";

import { fetchListData } from "../actions/shifts.actions";
import { useDispatch, useSelector } from "../hooks";

export const useShiftsLoadingState = () =>
  useSelector(({ shifts }) => shifts.loading);

export const useShiftsListLoadingState = () =>
  useSelector(({ shifts }) => shifts.list.isLoading);

export const useHaveShiftsFetched = () =>
  useSelector(({ shifts }) => shifts.list.data !== null);

export const useShiftsActions = () => {
  const distpatch = useDispatch();

  const handleFetchShifts = useCallback(
    (params: Omit<FetchWorkerShiftsQueryParams, "timeZone">) =>
      distpatch(fetchListData(params)),
    [distpatch]
  );

  return {
    handleFetchShifts,
  };
};
