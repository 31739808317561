import { Button, SearchInput } from "@nowsta/tempo-ds";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { useUserCompanies } from "@/redux/hooks/companies.hooks";
import { LINKS } from "@/routing/paths";

import * as Presenter from "./CompanySelector.presenter";
import { SelectableCompanyItem } from "./components/SelectableCompanyItem";

export const CompanySelector = () => {
  const { t } = useTranslation();
  const companies = useUserCompanies();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 250);

  const sortedCompanies = useMemo(
    () =>
      [...companies].sort((c1, c2) =>
        c1.name.toLocaleLowerCase().localeCompare(c2.name.toLocaleLowerCase())
      ),
    [companies]
  );

  const filteredCompanies = useMemo(() => {
    if (debouncedSearch) {
      return sortedCompanies.filter(({ name }) =>
        name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())
      );
    }
    return sortedCompanies;
  }, [sortedCompanies, debouncedSearch]);

  return (
    <Presenter.Wrapper>
      <header>
        <Button
          uiSize="small"
          uiStyle="text"
          palette="ui"
          as={NavLink}
          to={LINKS.logout}
        >
          {t("pages.companySelector.logout")}
        </Button>
      </header>
      <Presenter.ContentBox>
        <Presenter.ContentHeader>
          {t("pages.companySelector.title")}
        </Presenter.ContentHeader>
        <Presenter.Text>
          {t("pages.companySelector.welcomeMessage")}
        </Presenter.Text>

        {companies.length > 10 && (
          <Presenter.SearchInput>
            <SearchInput
              placeholder={t("pages.companySelector.inputs.search.placeholder")}
              value={search}
              onChange={setSearch}
              sizeUI={{ w: "100%" }}
            />
          </Presenter.SearchInput>
        )}

        <Presenter.CompanyList>
          {companies.length > 0 ? (
            <>
              {filteredCompanies.map((company) => (
                <SelectableCompanyItem key={company.id} company={company} />
              ))}
            </>
          ) : (
            t("pages.companySelector.noResultsMessage")
          )}
        </Presenter.CompanyList>
      </Presenter.ContentBox>
    </Presenter.Wrapper>
  );
};
