import { DateTime } from "luxon";

import { Shift } from "@/pages/Workers/useShifts";

export const canClockIn = (
  { shiftDetails }: Pick<Shift, "shiftDetails">,
  clockInWindowMinutes = 30
) => {
  const now = DateTime.now();
  return (
    now.plus({ minutes: clockInWindowMinutes }) >=
    shiftDetails.schedule.startTime
  );
};

export const getBreakTimes = ({
  shiftDetails,
  shiftEvents,
}: Pick<Shift, "shiftDetails" | "shiftEvents">) => {
  const duration = shiftDetails.breaks.duration;

  if (!duration || !shiftEvents.clockIn) {
    return null;
  }

  const now = DateTime.now();

  if (now < shiftEvents.clockIn) {
    return {
      start: shiftEvents.clockIn,
      end: shiftEvents.clockIn.plus({ minutes: duration }),
    };
  }

  if (now.minus({ minutes: duration }) < shiftEvents.clockIn) {
    return {
      start: now,
      end: now.plus({ minutes: duration }),
    };
  }

  return {
    start: now.minus({ minutes: duration }),
    end: now,
  };
};

export const canClockOut = ({ shiftEvents }: Pick<Shift, "shiftEvents">) => {
  if (!shiftEvents.clockIn) {
    return false;
  }

  return DateTime.now() >= shiftEvents.clockIn;
};
