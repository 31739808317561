import { User as IconUser } from "@nowsta/tempo-ds";
import { forwardRef } from "react";

import { extractInitials } from "@/helpers/extractInitials";

import * as Presenter from "./Thumbnail.presenter";

export interface ThumbnailProps extends Presenter.ThumbnailImageProps {
  name: string;
  className?: string;
  title?: string;
}

export const Thumbnail = forwardRef<HTMLDivElement, ThumbnailProps>(
  ({ name, src, uiSize, className, title, $borderRadius, $isRounded }, ref) => {
    return (
      <Presenter.Main
        ref={ref}
        className={className}
        uiSize={uiSize}
        src={src}
        role="img"
        title={title}
        $borderRadius={$borderRadius}
        $isRounded={$isRounded}
      >
        {!src && `${extractInitials(name)}`}
      </Presenter.Main>
    );
  }
);

Thumbnail.displayName = "Thumbnail";

export interface WorkerThumbnailProps extends Partial<ThumbnailProps> {
  isPlaceholder?: boolean;
}

export const WorkerThumbnail = forwardRef<HTMLDivElement, WorkerThumbnailProps>(
  ({ name, isPlaceholder, src, ...props }, ref) => {
    const imageRoleProps = isPlaceholder
      ? { role: "presentation" }
      : { role: "img", alt: name };

    const initials = extractInitials(name);
    const fallback =
      initials.length > 1 ? `${initials[0]}${initials.slice(-1)}` : initials[0];

    return (
      <Presenter.Main
        ref={ref}
        src={src}
        $isRounded
        $isPlaceholder={isPlaceholder}
        {...imageRoleProps}
        {...props}
      >
        {isPlaceholder ? <IconUser /> : !src && `${fallback}`}
      </Presenter.Main>
    );
  }
);

WorkerThumbnail.displayName = "WorkerThumbnail";
