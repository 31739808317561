import { DateTime } from "luxon";

export const getTimeclockDateRange = (date?: string | null) => {
  const now = date
    ? DateTime.fromFormat(date, "yyyy-MM-dd").set({ hour: 4 })
    : DateTime.now();

  const dayBoundary = now.set({ hour: 4 }).startOf("hour");

  if (now < dayBoundary) {
    return {
      start: dayBoundary.minus({ days: 1 }),
      end: dayBoundary,
    };
  }

  return {
    start: dayBoundary,
    end: dayBoundary.plus({ days: 1 }),
  };
};
