import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputMessage, XMark } from "@nowsta/tempo-ds";
import { DateTime } from "luxon";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Shift } from "@/pages/Workers/useShifts";
import { EditTimecardRequestBody } from "@/types/Timecards";

import { FieldRow } from "../FormLayout";
import * as Presenter from "./EditTimecardModal.presenter";

export type EditTimecardModalData = {
  timecard: Shift;
};

export interface EditTimeCardFormData {
  timecardId: string;
  data: EditTimecardRequestBody;
}

type TimeField = "clockIn" | "clockOut" | "breakClockIn" | "breakClockOut";

const getTimePickerString = (isoString: string) =>
  DateTime.fromISO(isoString).toFormat("hh:mm a");

const schema = yup.object({
  clockIn: yup.string(),
  clockOut: yup
    .string()
    .test(
      "min-end-time",
      "components.editTimecardModal.schema.clockOut.invalidMinEndTime",
      (clockOut, context) => {
        const { clockIn } = context.parent;

        if (!clockIn || !clockOut) {
          return true;
        }

        const actualIn = DateTime.fromISO(clockIn);
        const actualOut = DateTime.fromISO(clockOut);

        return actualIn < actualOut;
      }
    ),
  breakClockIn: yup
    .string()
    .test(
      "max-general-break-start-time",
      "components.editTimecardModal.schema.breakClockIn.invalidGeneralMinBreakStartTime",
      (breakClockIn, context) => {
        const { clockIn, clockOut } = context.parent;
        const hasClockOut = Boolean(clockOut);

        if (!breakClockIn || !clockIn) {
          return true;
        }

        const actualBreakIn = DateTime.fromISO(breakClockIn);
        const actualIn = DateTime.fromISO(clockIn);
        const actualOut = DateTime.fromISO(clockOut);

        return (
          actualBreakIn > actualIn &&
          (!hasClockOut || actualBreakIn < actualOut)
        );
      }
    ),
  breakClockOut: yup
    .string()
    .test(
      "max-break-end-time",
      "components.editTimecardModal.schema.breakClockOut.invalidMinBreakEndTime",
      (breakClockOut, context) => {
        const { breakClockIn } = context.parent;

        if (!breakClockOut || !breakClockIn) {
          return true;
        }

        const actualBreakIn = DateTime.fromISO(breakClockIn);
        const actualBreakOut = DateTime.fromISO(breakClockOut);

        return actualBreakIn < actualBreakOut;
      }
    )
    .test(
      "max-general-break-end-time",
      "components.editTimecardModal.schema.breakClockOut.invalidGeneralMinBreakEndTime",
      (breakClockOut, context) => {
        const { clockIn, clockOut } = context.parent;
        const hasClockOut = Boolean(clockOut);

        if (!breakClockOut || !clockIn) {
          return true;
        }

        const actualBreakOut = DateTime.fromISO(breakClockOut);
        const actualIn = DateTime.fromISO(clockIn);
        const actualOut = DateTime.fromISO(clockOut);

        return (
          actualBreakOut > actualIn &&
          (!hasClockOut || actualBreakOut < actualOut)
        );
      }
    ),
});

type FormData = yup.InferType<typeof schema>;

interface EditTimecardModalFormProps {
  data: EditTimecardModalData;
  isSubmitting?: boolean;
  onSubmit: (params: EditTimecardRequestBody) => void;
}

export const EditTimecardModalForm = ({
  data: { timecard },
  isSubmitting,
  onSubmit,
}: EditTimecardModalFormProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    id: timecard.id,
    clockIn: timecard.shiftEvents.clockIn?.toISO() || "",
    clockOut: timecard.shiftEvents.clockOut?.toISO() || "",
    breakClockIn: timecard.shiftEvents.breakClockIn?.toISO() || "",
    breakClockOut: timecard.shiftEvents.breakClockOut?.toISO() || "",
  };

  const form = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, watch, setValue, formState, trigger } = form;

  const {
    clockIn: baseClockIn,
    clockOut: baseClockOut,
    breakClockIn: baseBreakClockIn,
    breakClockOut: baseBreakClockOut,
  } = watch();

  const isWorking = isSubmitting;
  const hasClockIn = Boolean(timecard.shiftEvents.clockIn);
  const hasClockOut = Boolean(timecard.shiftEvents.clockOut);
  const hasBreakClockIn = Boolean(timecard.shiftEvents.breakClockIn);
  const hasBreakClockOut = Boolean(timecard.shiftEvents.breakClockOut);

  const breakErrors = [
    ...(formState.errors.breakClockIn?.message
      ? [t(formState.errors.breakClockIn.message)]
      : []),
    ...(formState.errors.breakClockOut?.message
      ? [t(formState.errors.breakClockOut.message)]
      : []),
  ]
    .filter((e: string) => e !== "")
    .join(", ");

  const [startTimeString, setStartTimeString] = useState(
    getTimePickerString(baseClockIn || "")
  );

  const [endTimeString, setEndTimeString] = useState(
    getTimePickerString(baseClockOut || "")
  );

  const [breakClockInTimeString, setBreakClockInTimeString] = useState(
    getTimePickerString(baseBreakClockIn || "")
  );

  const [breakClockOutTimeString, setBreakClockOutTimeString] = useState(
    getTimePickerString(baseBreakClockOut || "")
  );

  const handleUpdate = (formData: FormData) => {
    const hasBreaks = formData.breakClockIn && formData.breakClockOut;

    onSubmit({
      clockInAt: formData.clockIn || null,
      clockOutAt: formData.clockOut || null,
      workBreaks: hasBreaks
        ? [
            {
              breakClockInAt: formData.breakClockIn || "",
              breakClockOutAt: formData.breakClockOut || "",
            },
          ]
        : [],
    });
  };

  const handleTimeChange = (timeField: TimeField, timeString: string) => {
    let handler;
    let day: DateTime | null;

    switch (timeField) {
      case "clockIn":
        handler = setStartTimeString;
        day = timecard.shiftDetails.schedule.startTime;
        break;
      case "clockOut":
        handler = setEndTimeString;
        day = timecard.shiftDetails.schedule.endTime;
        break;
      case "breakClockIn":
        handler = setBreakClockInTimeString;
        day = timecard.shiftEvents.breakClockIn;
        break;
      case "breakClockOut":
        handler = setBreakClockOutTimeString;
        day = timecard.shiftEvents.breakClockOut;
        break;
    }

    const timeDay = day?.toFormat("LLL dd, yyyy");

    const updatedDateTime = DateTime.fromFormat(
      `${timeDay} ${timeString}`,
      "LLL dd, yyyy hh:mm a"
    );

    if (!updatedDateTime.invalidReason) {
      handler(timeString);
      setValue(timeField, updatedDateTime.toISO() || "");
    }

    if (timeString === "") {
      handler("");
      setValue(timeField, "");
    }
  };

  const handleSetScheduledStartTime = () => {
    handleTimeChange(
      "clockIn",
      getTimePickerString(
        timecard.shiftDetails.schedule.startTime.toISO() || ""
      )
    );

    trigger();
  };

  const handleSetScheduledEndTime = () => {
    handleTimeChange(
      "clockOut",
      getTimePickerString(timecard.shiftDetails.schedule.endTime.toISO() || "")
    );

    trigger();
  };

  const handleTimeClear = (timeField: TimeField): void => {
    handleTimeChange(timeField, "");

    trigger();
  };

  return (
    <Presenter.FormWrapper>
      <FormProvider {...form}>
        {hasClockIn && (
          <Controller
            name={`clockIn`}
            control={control}
            render={({ fieldState: { error: startError } }) => (
              <FieldRow boxUI={{ flexDir: "column" }}>
                <FieldRow
                  boxUI={{
                    crossAlign: "flex-end",
                  }}
                >
                  <Presenter.FormRowFullWidth
                    boxUI={{
                      gap: 16,
                      crossAlign: "flex-end",
                      mainAlign: "space-between",
                    }}
                  >
                    <Presenter.CustomAlignedTimePicker
                      label={t(
                        "components.editTimecardModal.inputs.clockIn.label"
                      )}
                      value={startTimeString}
                      onChange={(e) =>
                        handleTimeChange("clockIn", e.target.value)
                      }
                      onBlur={() => {
                        trigger();
                        const split = startTimeString.split(":");
                        if (split.some((chunk) => !chunk)) {
                          handleTimeChange("clockIn", "");
                        }
                      }}
                      uiStyle="outline"
                      palette={startError ? "critical" : undefined}
                      aria-invalid={Boolean(startError)}
                      disabled={!hasClockIn}
                      $mobileAlignToTop={!hasClockOut || !hasBreakClockIn}
                    />

                    <Presenter.CustomButton
                      palette="ui"
                      uiStyle="outline"
                      uiSize="medium"
                      disabled={!hasClockIn || isWorking}
                      onClick={handleSetScheduledStartTime}
                    >
                      {t(
                        "components.editTimecardModal.buttons.useScheduledTime"
                      )}
                    </Presenter.CustomButton>

                    <Presenter.CustomButton
                      palette="ui"
                      uiStyle="outline"
                      uiSize="medium"
                      disabled={!hasClockIn || isWorking}
                      onClick={() => {
                        handleTimeClear("clockIn");
                      }}
                      aria-label="clear clock in time"
                      iconLeft={<XMark focusable="false" aria-hidden="true" />}
                    />
                  </Presenter.FormRowFullWidth>
                </FieldRow>

                {startError?.message && (
                  <InputMessage isCritical>
                    {t(startError.message)}
                  </InputMessage>
                )}
              </FieldRow>
            )}
          />
        )}

        {(hasBreakClockIn || hasBreakClockOut) && (
          <FieldRow boxUI={{ flexDir: "column" }}>
            <Presenter.FormRowFullWidth
              boxUI={{
                crossAlign: "flex-end",
                mainAlign: "space-between",
              }}
            >
              <>
                {hasBreakClockIn && (
                  <Controller
                    name={`breakClockIn`}
                    control={control}
                    render={({ fieldState: { error: breakStartError } }) => (
                      <Presenter.CustomAlignedTimePicker
                        label={t(
                          "components.editTimecardModal.inputs.breakClockIn.label"
                        )}
                        value={breakClockInTimeString}
                        onChange={(e) =>
                          handleTimeChange("breakClockIn", e.target.value)
                        }
                        onBlur={() => {
                          trigger();

                          const split = endTimeString.split(":");
                          if (split.some((chunk) => !chunk)) {
                            handleTimeChange("breakClockIn", "");
                          }
                        }}
                        uiStyle="outline"
                        palette={breakStartError ? "critical" : undefined}
                        aria-invalid={Boolean(breakStartError)}
                        disabled={!hasBreakClockIn}
                        $mobileAlignToTop={true}
                      />
                    )}
                  />
                )}

                {hasBreakClockOut && (
                  <>
                    <Presenter.FieldSeparator>-</Presenter.FieldSeparator>

                    <Controller
                      name={`breakClockOut`}
                      control={control}
                      render={({ fieldState: { error: breakEndError } }) => (
                        <Presenter.CustomAlignedTimePicker
                          label={t(
                            "components.editTimecardModal.inputs.breakClockOut.label"
                          )}
                          value={breakClockOutTimeString}
                          onChange={(e) =>
                            handleTimeChange("breakClockOut", e.target.value)
                          }
                          onBlur={() => {
                            trigger();

                            const split = endTimeString.split(":");
                            if (split.some((chunk) => !chunk)) {
                              handleTimeChange("breakClockOut", "");
                            }
                          }}
                          uiStyle="outline"
                          palette={breakEndError ? "critical" : undefined}
                          aria-invalid={Boolean(breakEndError)}
                          disabled={!hasBreakClockOut}
                          $mobileAlignToLeft={true}
                          $mobileAlignToTop={true}
                        />
                      )}
                    />
                  </>
                )}

                <Presenter.CustomButton
                  palette="ui"
                  uiStyle="outline"
                  uiSize="medium"
                  disabled={!hasBreakClockOut}
                  onClick={() => {
                    if (hasBreakClockIn) handleTimeClear("breakClockIn");
                    if (hasBreakClockOut) handleTimeClear("breakClockOut");
                  }}
                  aria-label="clear break time"
                  iconLeft={<XMark focusable="false" aria-hidden="true" />}
                />
              </>
            </Presenter.FormRowFullWidth>

            {breakErrors && (
              <InputMessage isCritical>{breakErrors}</InputMessage>
            )}
          </FieldRow>
        )}

        {hasClockOut && (
          <Controller
            name={`clockOut`}
            control={control}
            render={({ fieldState: { error: endError } }) => (
              <FieldRow boxUI={{ flexDir: "column" }}>
                <FieldRow
                  boxUI={{
                    crossAlign: "flex-end",
                    gap: 24,
                  }}
                >
                  <Presenter.FormRowFullWidth
                    boxUI={{
                      gap: 16,
                      crossAlign: "flex-end",
                      mainAlign: "space-between",
                    }}
                  >
                    <Presenter.CustomAlignedTimePicker
                      label={t(
                        "components.editTimecardModal.inputs.clockOut.label"
                      )}
                      value={endTimeString}
                      onChange={(e) =>
                        handleTimeChange("clockOut", e.target.value)
                      }
                      onBlur={() => {
                        trigger();

                        const split = endTimeString.split(":");
                        if (split.some((chunk) => !chunk)) {
                          handleTimeChange("clockOut", "");
                        }
                      }}
                      uiStyle="outline"
                      palette={endError ? "critical" : undefined}
                      aria-invalid={Boolean(endError)}
                      disabled={!hasClockOut}
                      $mobileAlignToTop={true}
                    />

                    <Presenter.CustomButton
                      palette="ui"
                      uiStyle="outline"
                      uiSize="medium"
                      disabled={!hasClockOut || isWorking}
                      onClick={handleSetScheduledEndTime}
                    >
                      {t(
                        "components.editTimecardModal.buttons.useScheduledTime"
                      )}
                    </Presenter.CustomButton>

                    <Presenter.CustomButton
                      palette="ui"
                      uiStyle="outline"
                      uiSize="medium"
                      disabled={!hasClockOut || isWorking}
                      onClick={() => {
                        handleTimeClear("clockOut");
                      }}
                      aria-label="clear time"
                      iconLeft={<XMark focusable="false" aria-hidden="true" />}
                    ></Presenter.CustomButton>
                  </Presenter.FormRowFullWidth>
                </FieldRow>

                {endError?.message && (
                  <InputMessage isCritical>{t(endError.message)}</InputMessage>
                )}
              </FieldRow>
            )}
          />
        )}
      </FormProvider>

      <Button
        palette="primary"
        onClick={handleSubmit(handleUpdate, console.error)}
        isWorking={isWorking}
      >
        {t("components.editTimecardModal.buttons.confirm")}
      </Button>
    </Presenter.FormWrapper>
  );
};
