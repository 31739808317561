import { useSelector } from "../hooks";
import { usersAdapterSelectors } from "../slices/users.slice";

export const useCurrentUser = () =>
  useSelector(({ session, users }) => {
    if (!session.user.data) {
      return null;
    }

    return usersAdapterSelectors.selectById(users, session.user.data) || null;
  });
