import { useEffect } from "react";

import { useSelectedCompany } from "@/redux/hooks/companies.hooks";
import { useShiftsActions } from "@/redux/hooks/shifts.hooks";

export const useFetchShifts = (
  intervalSeconds = 60,
  dateToShow: string | null
) => {
  const { id: clientId } = useSelectedCompany();
  const { handleFetchShifts } = useShiftsActions();

  useEffect(() => {
    handleFetchShifts(
      dateToShow ? { clientId, date: dateToShow } : { clientId }
    );
    const intervalId =
      intervalSeconds > 0
        ? setInterval(() => {
            handleFetchShifts(
              dateToShow ? { clientId, date: dateToShow } : { clientId }
            );
          }, 1000 * intervalSeconds)
        : null;

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [clientId, dateToShow, handleFetchShifts, intervalSeconds]);
};
