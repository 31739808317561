import {
  BoxUIProps,
  DisplayProps,
  MapNoWrapper,
  SizeUIProps,
  SpaceUIProps,
  boxHelper,
  color,
  displayHelper,
  rem,
  reserved,
  sizeHelper,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled, { css } from "styled-components";

import { TABLET_QUERY } from "@/helpers/breakpoints";

export const Form = styled.form<SizeUIProps & BoxUIProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(48)};

  ${sizeHelper}
  ${boxHelper}

  textarea {
    // need to adjust for padding around the textarea
    height: ${rem(112 - 22)};
  }
`;

type SectionProps = { hasStepNumber?: boolean } & SpaceUIProps & BoxUIProps;

export const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};

  ${({ hasStepNumber }) =>
    hasStepNumber &&
    css`
      padding-left: ${rem(40)};
    `}

  textarea {
    // need to adjust for padding around the textarea
    height: ${rem(112 - 22)};
  }

  ${displayHelper}
`;

export const BorderedSection = styled(Section)`
  border-radius: ${rem(reserved.corners.medium)};
  border: ${rem(1)} solid ${color.ui20};
  padding: ${rem(20)};
`;

export const SectionHeader = styled.div<{ stepNumber?: number }>`
  position: relative;
  ${textSize.headlineM}
  font-weight: ${textWeight.bold};
  color: ${color.ui160};

  padding: ${rem(2)} 0 ${rem(16)};
  border-bottom: ${rem(1)} dashed ${color.ui60};

  ${({ stepNumber }) =>
    !!stepNumber &&
    css`
      :before {
        content: "${stepNumber}";

        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        top: 0;
        transform: translateX(calc(-100% - ${rem(16)}));

        width: ${rem(24)};
        height: ${rem(24)};
        border-radius: 50%;

        ${textSize.headlineS}
        font-weight: ${textWeight.medium};
        text-align: center;

        background: ${color.ui144};
        color: white;

        ${TABLET_QUERY.start} {
          transform: translateX(calc(-100% - ${rem(8)}));
        }
      }
    `}
`;

export const SectionTitle = styled.div`
  ${textSize.headlineL}
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};
`;

export const SectionInnerTitle = styled.div`
  ${textSize.headlineM}
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};
`;

export const SectionDescription = styled.div`
  ${textSize.bodyM}
  font-weight: ${textWeight.regular};
  color: ${color.ui111};

  & strong {
    font-weight: ${textWeight.semiBold};
  }
`;

export const FieldGroup = styled.div<DisplayProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  ${displayHelper};
`;

export const GroupLabel = styled.div`
  ${textSize.headlineS}
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};
`;

export const FieldRow = styled.div<BoxUIProps>`
  display: flex;
  gap: ${rem(8)};
  ${boxHelper}

  legend {
    margin-bottom: ${rem(8)};
  }
`;

export const FormGrid = styled.div<{ gridTemplateColumns?: string }>`
  display: grid;
  gap: ${rem(8)};

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns && `grid-template-columns: ${gridTemplateColumns}`}
`;

export const MapImageStyles = css`
  img {
    border: 1px solid ${color.slate20};
    border-radius: ${rem(8)};
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const StyledMap = styled(MapNoWrapper)`
  width: 100%;
  height: ${rem(280)};
`;

export const MapContainer = styled.div<{ isWorking?: boolean }>`
  position: relative;
  width: 100%;
  height: ${rem(280)};

  ${({ isWorking }) =>
    isWorking &&
    css`
      pointer-events: none;
    `}
`;

export const MapScreen = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${rem(reserved.corners.default)};
  border: ${rem(1)} solid ${color.shadows06};
  background-color: rgba(240, 242, 244, 0.68);
  backdrop-filter: blur(${rem(4)});

  svg.icon {
    width: ${rem(24)};
    height: ${rem(24)};
  }
`;

export const Note = styled.span<SpaceUIProps & BoxUIProps>`
  ${textSize.labelM}
  font-weight: ${textWeight.regular};

  color: ${color.ui160};

  ${displayHelper}

  b {
    font-weight: ${textWeight.semiBold};
  }
`;

export const Hidden = styled.div`
  display: none;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ErrorMessage = styled.p`
  ${textSize.bodyS};
  font-weight: ${textWeight.regular};
  color: ${color.red100};
`;

export const HiddenSubmit = () => <HiddenInput type="submit" />;
