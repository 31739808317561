import {
  Break,
  Button,
  DotsVertical,
  DropDown,
  DropDownHeader,
  DropDownSlat,
  Edit,
  IconText,
  MenuButtonHelper,
  Text,
} from "@nowsta/tempo-ds";
import { useTranslation } from "react-i18next";

import { Shift } from "@/pages/Workers/useShifts";
import { ShiftLoadingState } from "@/redux/slices/shifts.slice";
import { TimecardWorkerStatusFilter } from "@/types/Timecards";
import { ShiftEvent } from "@/types/WorkerShift";

import { AvatarWithSource } from "../AvatarWithSource";
import * as Presenter from "./ShiftCard.presenter";

interface Props {
  timecard: Shift;
  loadingState?: ShiftLoadingState;
  onEvent: (timecard: Shift, event: ShiftEvent) => void;
}

const STATUS_TRANSLATION_CONTEXT: Record<TimecardWorkerStatusFilter, string> = {
  "clocked-in": "clockedIn",
  "clocked-out": "clockedOut",
  "not-clocked-in": "notClockedIn",
};

interface ActionButtonProps {
  status: TimecardWorkerStatusFilter;
  hasNote: boolean;
  hasBreak: boolean;
  loadingState?: ShiftLoadingState;
  onEvent: (event: ShiftEvent) => void;
}

export const ActionButtons = ({
  hasNote,
  status,
  hasBreak,
  loadingState,
  onEvent,
}: ActionButtonProps) => {
  const { t } = useTranslation();

  if (status === "clocked-in" && hasBreak) {
    return (
      <Presenter.ButtonGroup>
        <Button
          palette="ui"
          uiStyle="secondary"
          isWorking={loadingState?.editTimecard}
          onClick={() => onEvent("editTimecard")}
        >
          {t("components.shiftCard.buttons.editTimecard")}
        </Button>
        <Button
          palette="ui"
          onClick={() => onEvent("clockOut")}
          isWorking={loadingState?.clockOut}
        >
          {t("components.shiftCard.buttons.clockOut")}
        </Button>
      </Presenter.ButtonGroup>
    );
  }

  if (status === "clocked-in") {
    return (
      <Presenter.ButtonGroup>
        <Button
          palette="ui"
          uiStyle="secondary"
          onClick={() => onEvent("addBreak")}
          isWorking={loadingState?.addBreak}
        >
          {t("components.shiftCard.buttons.addBreak")}
        </Button>
        <Button
          palette="ui"
          onClick={() => onEvent("clockOut")}
          isWorking={loadingState?.clockOut}
        >
          {t("components.shiftCard.buttons.clockOut")}
        </Button>
      </Presenter.ButtonGroup>
    );
  }

  if (status === "clocked-out") {
    return (
      <Presenter.ButtonGroup>
        <Button
          palette="ui"
          uiStyle="secondary"
          onClick={() => onEvent("editTimecard")}
          isWorking={loadingState?.editTimecard}
        >
          {t("components.shiftCard.buttons.editTimecard")}
        </Button>
        <Button
          palette="ui"
          uiStyle="secondary"
          onClick={() => onEvent("addNote")}
          isWorking={loadingState?.addNote}
        >
          {t("components.shiftCard.buttons.note", {
            context: hasNote ? "edit" : "add",
          })}
        </Button>
      </Presenter.ButtonGroup>
    );
  }

  return (
    <Presenter.ButtonGroup>
      <Button
        palette="primary"
        onClick={() => onEvent("clockIn")}
        isWorking={loadingState?.clockIn}
      >
        {t("components.shiftCard.buttons.clockInNow")}
      </Button>
      <Button
        palette="primary"
        onClick={() => onEvent("clockInOnTime")}
        isWorking={loadingState?.clockInOnTime}
      >
        {t("components.shiftCard.buttons.clockInOnTime")}
      </Button>
    </Presenter.ButtonGroup>
  );
};

interface ShiftMenuProps extends Omit<ActionButtonProps, "loadingState"> {
  name: string;
}

export const ShiftMenu = ({
  hasNote,
  status,
  name,
  hasBreak,
  onEvent,
}: ShiftMenuProps) => {
  const { t } = useTranslation();

  if (status === "clocked-out") {
    return null;
  }

  return (
    <MenuButtonHelper
      menu={
        <DropDown w={200}>
          <DropDownHeader>{t("components.shiftCard.menuTitle")}</DropDownHeader>
          {status === "clocked-in" && !hasBreak && (
            <DropDownSlat
              icon={<Edit />}
              onClick={() => onEvent("editTimecard")}
            >
              {t("components.shiftCard.buttons.editTimecard")}
            </DropDownSlat>
          )}
          <DropDownSlat icon={<Text />} onClick={() => onEvent("addNote")}>
            {t("components.shiftCard.buttons.note", {
              context: hasNote ? "edit" : "add",
            })}
          </DropDownSlat>
        </DropDown>
      }
      button={
        <Button
          iconLeft={<DotsVertical />}
          aria-label={t("components.shiftCard.buttons.actions", {
            workerName: name,
          })}
          uiStyle="secondary"
          palette="white"
        />
      }
    />
  );
};

export const ShiftCard = ({ timecard, loadingState, onEvent }: Props) => {
  const { t } = useTranslation();
  const { company, shiftDetails, shiftEvents, worker } = timecard;
  const hasNote = shiftEvents.note !== null;

  return (
    <Presenter.Card>
      <Presenter.Top>
        <Presenter.Overview>
          <AvatarWithSource
            name={worker.name}
            imgUrl={worker.imgUrl}
            source={{
              name: company.name,
              imgUrl: company.imgUrl,
            }}
          />
          <Presenter.OverviewRight>
            <Presenter.WorkerName>{worker.name}</Presenter.WorkerName>
            <Presenter.Position>{shiftDetails.position}</Presenter.Position>
            <Presenter.ShiftDetailList>
              <Presenter.Schedule>
                {t("components.shiftCard.schedule", {
                  startTime: shiftDetails.schedule.startTime.toJSDate(),
                  endTime: shiftDetails.schedule.endTime.toJSDate(),
                  formatParams: {
                    startTime: { hour: "numeric", minute: "numeric" },
                    endTime: { hour: "numeric", minute: "numeric" },
                  },
                })}
              </Presenter.Schedule>
              <Presenter.Breaks>
                <IconText icon={<Break />}>
                  {t("components.shiftCard.break", {
                    context: shiftDetails.breaks.isPaid ? "paid" : "unpaid",
                    duration: shiftDetails.breaks.duration,
                  })}
                </IconText>
              </Presenter.Breaks>
            </Presenter.ShiftDetailList>
          </Presenter.OverviewRight>
        </Presenter.Overview>
        <Presenter.Actions>
          <Presenter.Status
            palette={shiftEvents.status === "clocked-in" ? "primary" : "ui"}
          >
            {t("components.shiftCard.status", {
              context: STATUS_TRANSLATION_CONTEXT[shiftEvents.status],
            })}
          </Presenter.Status>
          <ShiftMenu
            hasNote={hasNote}
            name={worker.name}
            status={shiftEvents.status}
            hasBreak={shiftEvents.hasBreak}
            onEvent={(e) => onEvent(timecard, e)}
          />
        </Presenter.Actions>
      </Presenter.Top>
      <Presenter.Bottom>
        {shiftEvents.status !== "not-clocked-in" && (
          <Presenter.TimecardDetails
            isClockedIn={shiftEvents.status === "clocked-in"}
          >
            {shiftEvents.clockIn && (
              <button onClick={() => onEvent(timecard, "editTimecard")}>
                <dt>{t("components.shiftCard.clockInTime.title")}</dt>
                <dd>
                  {t("components.shiftCard.clockInTime.value", {
                    clockIn: shiftEvents.clockIn.toJSDate(),
                    formatParams: {
                      clockIn: { hour: "numeric", minute: "numeric" },
                    },
                  })}
                </dd>
              </button>
            )}
            {shiftEvents.breakDuration && (
              <button
                className="extra-width"
                onClick={() => onEvent(timecard, "editTimecard")}
              >
                <dt>{t("components.shiftCard.breakDuration.title")}</dt>
                <dd>
                  {t("components.shiftCard.breakDuration.value", {
                    duration: shiftEvents.breakDuration,
                    formatParams: {
                      duration: { maximumFractionDigits: 0 },
                    },
                  })}
                </dd>
              </button>
            )}
            {shiftEvents.clockOut && (
              <button onClick={() => onEvent(timecard, "editTimecard")}>
                <dt>{t("components.shiftCard.clockOutTime.title")}</dt>
                <dd>
                  {t("components.shiftCard.clockOutTime.value", {
                    clockOut: shiftEvents.clockOut.toJSDate(),
                    formatParams: {
                      clockOut: { hour: "numeric", minute: "numeric" },
                    },
                  })}
                </dd>
              </button>
            )}
          </Presenter.TimecardDetails>
        )}
        <ActionButtons
          status={shiftEvents.status}
          hasNote={hasNote}
          hasBreak={shiftEvents.hasBreak}
          loadingState={loadingState}
          onEvent={(e) => onEvent(timecard, e)}
        />
      </Presenter.Bottom>
    </Presenter.Card>
  );
};
