import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { companiesReducer } from "./slices/companies.slice";
import { sessionReducer } from "./slices/session.slice";
import { shiftsReducer } from "./slices/shifts.slice";
import { usersReducer } from "./slices/users.slice";

const appReducer = combineReducers({
  session: sessionReducer,
  users: usersReducer,
  companies: companiesReducer,
  shifts: shiftsReducer,
});

export type RootState = ReturnType<typeof appReducer>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setUpStore = (preloadedState?: any) => {
  return configureStore({
    reducer: appReducer,
    preloadedState,
  });
};
export type AppStore = ReturnType<typeof setUpStore>;
export type AppDispatch = AppStore["dispatch"];
