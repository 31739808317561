export const addRemoveFromObject = <T extends object, K extends keyof T>(
  key: K,
  object: T,
  value: T[K]
) => {
  const { [key]: keyToRemove, ...otherProps } = object;

  if (keyToRemove) {
    return otherProps;
  }

  return {
    ...object,
    [key]: value,
  };
};
