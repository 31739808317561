import { mq } from "@nowsta/tempo-ds";

export const PHONE_QUERY = {
  start: mq.untilMobile.large,
  end: mq.fromMobile.large,
};

export const TABLET_QUERY = {
  start: mq.untilTablet.start,
  end: mq.fromTablet.start,
};
