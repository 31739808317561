import { ApiFunction } from "@/types/Api";
import { Company, FetchClientsQueryParams } from "@/types/Company";
import {
  FetchWorkerShiftsQueryParams,
  FetchWorkerShiftsResponse,
} from "@/types/WorkerShift";

import { createDefaultClient } from "./shared";

export const jobClient = createDefaultClient("core/jobs");

export const JOB_SERVICE_PATHS = {
  confirmedShifts: (_: FetchWorkerShiftsQueryParams) => `captains/shifts`,
  fetchClients: (_: FetchClientsQueryParams) => `captains/clients`,
};

type JobServicePaths = typeof JOB_SERVICE_PATHS;

export type JobService = {
  getConfirmedShifts: ApiFunction<
    FetchWorkerShiftsResponse,
    undefined,
    JobServicePaths["confirmedShifts"]
  >;
  fetchClients: ApiFunction<
    Company[],
    undefined,
    JobServicePaths["fetchClients"]
  >;
};

export const jobService: JobService = {
  getConfirmedShifts: async (params) => {
    const { data } = await jobClient.get(
      JOB_SERVICE_PATHS.confirmedShifts(params),
      { params }
    );

    return data;
  },
  fetchClients: async (params) => {
    const { data } = await jobClient.get(
      JOB_SERVICE_PATHS.fetchClients(params),
      { params }
    );

    return data;
  },
};
