import { ErrorPage } from "@nowsta/tempo-ds";
import React, { PropsWithChildren, ReactNode } from "react";

type TopLevelErrorBoundaryProps = PropsWithChildren<unknown>;

class ErrorBoundary extends React.Component<
  TopLevelErrorBoundaryProps,
  {
    hasError: boolean;
  }
> {
  constructor(props: TopLevelErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export const TopLevelErrorBoundary = ({
  children,
}: {
  children: ReactNode;
}) => <ErrorBoundary>{children}</ErrorBoundary>;
