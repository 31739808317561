import {
  UISize,
  color,
  rem,
  reserved,
  shadowAsBorderHelper,
  squareHelper,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled, { css } from "styled-components";

export interface ThumbnailImageProps {
  src?: string;
  uiSize?: Extract<UISize, "medium" | "large" | "xsmall" | "xlarge">;
  $borderRadius?: number;
  $isRounded?: boolean;
}

export const Main = styled.div<
  ThumbnailImageProps & { $isPlaceholder?: boolean }
>`
  ${({ uiSize = "medium" }) => {
    const fontSize =
      uiSize === "xsmall" ? `font-size: ${rem(10)};` : textSize.headlineS;

    const dimension = (() => {
      if (uiSize === "xlarge") {
        return 60;
      }

      if (uiSize === "large") {
        return 40;
      }

      if (uiSize === "medium") {
        return 32;
      }

      return 24;
    })();

    return css`
      width: ${rem(dimension)};
      aspect-ratio: 1 / 1;

      ${fontSize}
    `;
  }}

  display: flex;
  align-items: center;
  justify-content: center;

  border: ${rem(1)} solid white;
  border-radius: ${({ $isRounded, $borderRadius }) =>
    $isRounded ? rem(100) : rem($borderRadius || reserved.corners.default)};

  color: white;
  font-weight: ${textWeight.semiBold};

  ${({ src, $isPlaceholder, uiSize = "medium" }) => {
    if ($isPlaceholder) {
      const iconSize = uiSize === "xsmall" ? 24 : 32;

      return css`
        align-items: flex-start;
        background-color: ${color.ui10};
        outline: ${rem(1)} dashed ${color.ui60};
        outline-offset: ${rem(-2)};

        overflow: hidden;

        svg.icon__user {
          fill: ${color.ui60};
          margin-top: ${rem(3)};

          ${squareHelper(rem(iconSize))}
        }
      `;
    }

    if (src) {
      return css`
        background-color: ${color.ui20};
        background-image: url(${src});
        background-size: cover;
        background-position: center;

        box-shadow: ${shadowAsBorderHelper(1, color.shadows03)};
      `;
    }

    return css`
      background-color: ${color.ui111};
    `;
  }}
`;
