import { color, rem, textSize, textWeight } from "@nowsta/tempo-ds";
import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(12)};

  svg.icon__loading {
    width: ${rem(16)};
    height: ${rem(16)};

    fill: ${color.primary100};
  }
`;

export const Message = styled.div`
  ${textSize.labelM}
  font-weight: ${textWeight.medium};

  color: ${color.ui111};
`;
