import { createSelector } from "@reduxjs/toolkit";

import { getCompoundPositionId } from "@/helpers/getCompoundPositionId";
import { getPositionName } from "@/helpers/getPositionName";
import { useSelector } from "@/redux/hooks";
import { shiftsAdapterSelectors } from "@/redux/slices/shifts.slice";
import { RootState } from "@/redux/store";

interface PositionsStatus {
  id: string;
  clockedInCount: number;
  expectedCount: number;
  totalCount: number;
  name: string;
}

const getInitialStatus = ({
  id,
  isBackup,
  isClockedIn,
  name,
  totalCount,
}: {
  id: string;
  name: string;
  isClockedIn: boolean;
  isBackup: boolean;
  totalCount: number;
}): PositionsStatus => ({
  id,
  name,
  clockedInCount: isClockedIn ? 1 : 0,
  expectedCount: isBackup ? 0 : 1,
  totalCount: totalCount,
});

const updateStatus = (
  s: PositionsStatus,
  {
    isClockedIn,
    isBackup,
  }: {
    isClockedIn: boolean;
    isBackup: boolean;
  }
): PositionsStatus => ({
  ...s,
  clockedInCount: isClockedIn ? s.clockedInCount + 1 : s.clockedInCount,
  expectedCount: isBackup ? s.expectedCount : s.expectedCount + 1,
});

const positionsTotalsSelector = createSelector(
  [
    ({ shifts }: RootState) => shifts.entities,
    ({ shifts }: RootState) => shifts.ids,
  ],
  (shifts, ids) => {
    const positionCounts = shiftsAdapterSelectors
      .selectAll({ entities: shifts, ids })
      .reduce<Record<string, number>>((positions, s) => {
        const positionId = `${getCompoundPositionId(s)}~${s.roleId}.${s.jobId}`;
        const targetPosition = positions[positionId];

        if (!targetPosition) {
          return { ...positions, [positionId]: s.quantity || 0 };
        }

        return positions;
      }, {});

    const positionCountsSum = Object.entries(positionCounts).reduce<
      Record<string, number>
    >((sum, p) => {
      const sumId = p[0].split("~")[0];
      const targetPosition = sum[sumId];

      if (!targetPosition) {
        return { ...sum, [sumId]: p[1] || 0 };
      }

      return {
        ...sum,
        [sumId]: targetPosition + p[1],
      };
    }, {});

    return positionCountsSum;
  }
);

const memoizedPositionsSelector = createSelector(
  [
    ({ shifts }: RootState) => shifts.entities,
    ({ shifts }: RootState) => shifts.ids,
  ],
  (shifts, ids) => {
    const statuses = shiftsAdapterSelectors
      .selectAll({ entities: shifts, ids })
      .reduce<Record<string, PositionsStatus>>((positions, s) => {
        const positionId = getCompoundPositionId(s);
        const targetPosition = positions[positionId];
        const isBackup = s.status === "backup";
        const isClockedIn = !!s.actuals.clockInAt;

        if (!targetPosition) {
          return {
            ...positions,
            [positionId]: getInitialStatus({
              id: positionId,
              name: getPositionName(s),
              isBackup,
              isClockedIn,
              totalCount: s.quantity || 0,
            }),
          };
        }

        return {
          ...positions,
          [positionId]: updateStatus(targetPosition, {
            isBackup,
            isClockedIn,
          }),
        };
      }, {});

    return Object.values(statuses).sort(({ name: name1 }, { name: name2 }) =>
      name1.toLocaleLowerCase().localeCompare(name2.toLocaleLowerCase())
    );
  }
);

export const usePositions = () => useSelector(memoizedPositionsSelector);
export const usePositionsTotals = () => useSelector(positionsTotalsSelector);
