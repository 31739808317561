import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { getCompoundPositionId } from "@/helpers/getCompoundPositionId";
import { getPositionName } from "@/helpers/getPositionName";
import { DecoratedWorkerShift } from "@/redux/actions/shifts.actions";
import { useSelector } from "@/redux/hooks";
import { shiftsAdapterSelectors } from "@/redux/slices/shifts.slice";
import { RootState } from "@/redux/store";
import { TimecardWorkerStatusFilter } from "@/types/Timecards";

export interface Shift {
  id: string;
  worker: {
    id: string;
    name: string;
    imgUrl?: string;
  };
  company: {
    name: string;
    imgUrl?: string;
  };
  shiftDetails: {
    schedule: {
      startTime: DateTime;
      endTime: DateTime;
    };
    breaks: {
      duration: number | null;
      isPaid: boolean;
    };
    position: string;
    positionId: string;
    shiftTimeFilter: string;
  };
  shiftEvents: {
    status: TimecardWorkerStatusFilter;
    breakDuration: number | null;
    hasBreak: boolean;
    clockIn: DateTime | null;
    clockOut: DateTime | null;
    breakClockIn: DateTime | null;
    breakClockOut: DateTime | null;
    note: string | null;
  };
}

const getStatus = ({
  actuals,
}: DecoratedWorkerShift): TimecardWorkerStatusFilter => {
  if (!actuals.clockInAt) {
    return "not-clocked-in";
  }

  if (!actuals.clockOutAt) {
    return "clocked-in";
  }

  return "clocked-out";
};

const dateOrNull = (value?: string | null) => {
  if (!value) {
    return null;
  }

  return DateTime.fromISO(value);
};

const getBreakDuration = ({ actuals }: DecoratedWorkerShift) => {
  const [b] = actuals.workBreaks || [];

  if (!b || !b.breakClockInAt || !b.breakClockOutAt) {
    return null;
  }

  return DateTime.fromISO(b.breakClockOutAt).diff(
    DateTime.fromISO(b.breakClockInAt),
    ["minutes"]
  ).minutes;
};

const memoizedShiftsSelector = createSelector(
  [
    ({ shifts }: RootState) => shifts.entities,
    ({ shifts }: RootState) => shifts.ids,
  ],
  (shifts, ids) => {
    return shiftsAdapterSelectors
      .selectAll({ entities: shifts, ids })
      .map<Shift>((s) => {
        const [firstBreak] = s.actuals.workBreaks || [];
        return {
          id: s.id,
          worker: s.worker,
          company: s.company,
          shiftDetails: {
            schedule: {
              startTime: DateTime.fromISO(s.start),
              endTime: DateTime.fromISO(s.end),
            },
            breaks: {
              duration: parseInt(s.breaks.replaceAll(/\s.*$/g, "")) || null,
              isPaid: s.isBreakPaid,
            },
            position: getPositionName(s),
            positionId: getCompoundPositionId(s),
            shiftTimeFilter: DateTime.fromISO(s.start)
              .startOf("minute")
              .toISO() as string,
          },
          shiftEvents: {
            breakDuration: getBreakDuration(s),
            clockIn: dateOrNull(s.actuals.clockInAt),
            clockOut: dateOrNull(s.actuals.clockOutAt),
            hasBreak: Boolean(firstBreak && firstBreak.breakClockInAt),
            breakClockIn: dateOrNull(firstBreak?.breakClockInAt),
            breakClockOut: dateOrNull(firstBreak?.breakClockOutAt),
            note: s.actuals.note || null,
            status: getStatus(s),
          },
        };
      });
  }
);

export const useShifts = () => useSelector(memoizedShiftsSelector);
