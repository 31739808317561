import { logger } from "./logger";

export const reduceIdsToEntities = <T>(
  ids: string[],
  entities: Record<string, T>,
  entityName = "entity"
) =>
  ids.reduce<T[]>((prev, entityId) => {
    const entity = entities[entityId];
    if (entity) {
      return [...prev, entity];
    }
    logger.error(
      `${entityName} ID ${entityId} was not found, make sure you are fetching the appropriate entities first`
    );
    return [...prev];
  }, []);
