import { color, rem, textSize, textWeight } from "@nowsta/tempo-ds";
import styled from "styled-components";

import { PHONE_QUERY } from "@/helpers/breakpoints";

export const Page = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: 100%;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  padding: ${rem(21)} ${rem(16)};
  justify-content: center;
  border-bottom: ${rem(1)} solid ${color.ui20};
  background-color: white;
  z-index: 1000;

  ${PHONE_QUERY.start} {
    position: relative;
    justify-content: space-between;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(5)};

  > h6 {
    ${textSize.labelL};
    color: ${color.ui100};
  }

  ${PHONE_QUERY.start} {
    align-items: initial;

    svg {
      height: ${rem(12)};
      width: ${rem(62)};
    }

    > h6 {
      ${textSize.labelXS};
    }
  }
`;

export const DateIdWrapper = styled.div`
  ${PHONE_QUERY.start} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const TimeclockId = styled.p`
  ${textSize.headlineXS};
  position: absolute;
  height: 100%;
  top: ${rem(0)};
  left: ${rem(16)};
  display: flex;
  align-items: center;
  font-weight: ${textWeight.semiBold};

  ${PHONE_QUERY.start} {
    position: unset;
    ${textSize.labelXS};
  }
`;

export const Date = styled.div`
  position: absolute;
  height: 100%;
  top: ${rem(0)};
  right: ${rem(16)};
  display: flex;
  align-items: center;

  > p {
    ${textSize.headlineXS};

    > span {
      font-weight: ${textWeight.semiBold};
    }
  }

  ${PHONE_QUERY.start} {
    position: unset;

    > p {
      ${textSize.labelXS};
    }
  }
`;

export const Body = styled.div`
  padding: ${rem(24)};

  ${PHONE_QUERY.start} {
    padding: ${rem(16)};
    padding-top: 0;
  }
`;

export const NetworkOffline = styled.div`
  ${textSize.labelXS};
  background-color: ${color.red100};
  color: white;
  text-align: center;
`;
