import {
  CheckboxInput,
  CheckboxInputProps,
  InputLabel,
  PasswordInput,
  PasswordInputProps,
  SelectInput,
  SelectInputProps,
  StyledDiv,
  TextArea,
  TextAreaProps,
  TextInput,
  TextInputProps,
  ToggleSwitch,
  ToggleSwitchProps,
} from "@nowsta/tempo-ds";
import { TFunction, TOptions } from "i18next";
import React, { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { mergeRefs } from "@/helpers/mergeRefs";

interface ControlledProps {
  name: string;
  t?: TFunction;
  tOptions?: TOptions;
}

export interface ControlledTextInputProps
  extends Omit<TextInputProps, "name">,
    ControlledProps {}

const withTranslation = (
  t?: TFunction,
  value?: string,
  tOptions?: TOptions
) => {
  if (!t || !value) {
    return value;
  }

  if (tOptions) {
    return t(value, tOptions);
  }

  return t(value);
};

export const ControlledTextInput = React.forwardRef<
  HTMLInputElement,
  ControlledTextInputProps
>(({ name, message, palette, t, tOptions, ...props }, inputRef) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextInput
          tempoRef={mergeRefs(ref, inputRef)}
          {...props}
          {...field}
          palette={error ? "critical" : palette}
          message={
            error ? withTranslation(t, error.message, tOptions) : message
          }
          aria-invalid={Boolean(error)}
        />
      )}
    />
  );
});

ControlledTextInput.displayName = "ControlledTextInput";

interface ControlledTextAreaProps
  extends Omit<TextAreaProps, "name">,
    ControlledProps {}

export const ControlledTextArea = React.forwardRef<
  HTMLTextAreaElement,
  ControlledTextAreaProps
>(({ name, message, palette, t, tOptions, ...props }, inputRef) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextArea
          tempoRef={mergeRefs(ref, inputRef)}
          {...props}
          {...field}
          palette={error ? "critical" : palette}
          message={
            error ? withTranslation(t, error.message, tOptions) : message
          }
          aria-invalid={Boolean(error)}
        />
      )}
    />
  );
});

ControlledTextArea.displayName = "ControlledTextArea";

interface ControlledSelectInputProps
  extends Omit<SelectInputProps, "name">,
    ControlledProps {}

export const ControlledSelectInput = React.forwardRef<
  HTMLInputElement,
  ControlledSelectInputProps
>(({ name, message, palette, t, tOptions, ...props }, inputRef) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <SelectInput
          tempoRef={mergeRefs(ref, inputRef)}
          {...props}
          {...field}
          palette={error ? "critical" : palette}
          message={
            error ? withTranslation(t, error.message, tOptions) : message
          }
          aria-invalid={Boolean(error)}
        />
      )}
    />
  );
});

ControlledSelectInput.displayName = "ControlledSelectInput";

interface ControlledPasswordInputProps
  extends Omit<PasswordInputProps, "name">,
    ControlledProps {}

export const ControlledPasswordInput = React.forwardRef<
  HTMLInputElement,
  ControlledPasswordInputProps
>(({ name, message, palette, t, tOptions, ...props }, inputRef) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <PasswordInput
          tempoRef={mergeRefs(ref, inputRef)}
          {...props}
          {...field}
          palette={error ? "critical" : palette}
          message={
            error ? withTranslation(t, error.message, tOptions) : message
          }
          aria-invalid={Boolean(error)}
        />
      )}
    />
  );
});

ControlledPasswordInput.displayName = "ControlledPasswordInput";

interface ControlledCheckboxInputProps
  extends Omit<CheckboxInputProps, "name">,
    ControlledProps {}

export const ControlledCheckboxInput = React.forwardRef<
  HTMLInputElement,
  ControlledCheckboxInputProps
>(({ name, ...props }, inputRef) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ref, ...field },
        fieldState: { error },
      }) => (
        <CheckboxInput
          tempoRef={mergeRefs(ref, inputRef)}
          {...props}
          {...field}
          checked={value}
          onChange={() => onChange(!value)}
          aria-invalid={Boolean(error)}
        />
      )}
    />
  );
});

ControlledCheckboxInput.displayName = "ControlledCheckboxInput";

export interface ControlledToggleSwitchProps
  extends Omit<ToggleSwitchProps, "name" | "id">,
    ControlledProps {}

export const ControlledToggleSwitch = React.forwardRef<
  HTMLInputElement,
  ControlledTextInputProps
>(
  (
    { name, label, palette, t, tOptions, sizeUI, spaceUI, boxUI, ...props },
    inputRef
  ) => {
    const { control } = useFormContext();
    const id = useId();

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange, ...field },
          fieldState: { error },
        }) => (
          <StyledDiv sizeUI={sizeUI} spaceUI={spaceUI} boxUI={boxUI}>
            <StyledDiv boxUI={{ display: "flex", flexDir: "column", gap: 4 }}>
              {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
              <ToggleSwitch
                id={id}
                tempoRef={mergeRefs(ref, inputRef)}
                {...props}
                {...field}
                checked={value}
                onChange={() => onChange(!value)}
                palette={error ? "critical" : palette}
                aria-invalid={Boolean(error)}
              />
            </StyledDiv>
          </StyledDiv>
        )}
      />
    );
  }
);

ControlledToggleSwitch.displayName = "ControlledToggleSwitch";
