import { Navigate } from "react-router";

import { useCompaniesSelector } from "@/redux/hooks/companies.hooks";
import { useSessionSelector } from "@/redux/hooks/session.hooks";
import { LINKS } from "@/routing/paths";

export const Root = () => {
  const { companies } = useCompaniesSelector();
  const { savedCompanyId } = useSessionSelector();

  if (savedCompanyId) {
    return (
      <Navigate to={LINKS.companyHome({ companyId: savedCompanyId })} replace />
    );
  }

  if (companies.length === 1) {
    const [targetCompany] = companies;

    return (
      <Navigate
        to={LINKS.companyHome({ companyId: targetCompany.id })}
        replace
      />
    );
  }

  return <Navigate to={LINKS.companySelector} replace />;
};
