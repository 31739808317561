import { useTranslation } from "react-i18next";

import * as FormLayout from "@/components/FormLayout";

import {
  ControlledPasswordInput,
  ControlledTextInput,
} from "../inputs/controlled";

export const LogInFields = () => {
  const { t } = useTranslation();

  return (
    <FormLayout.FieldGroup>
      <ControlledTextInput
        t={t}
        name="email"
        label={t("pages.login.inputs.email.label")}
      />
      <ControlledPasswordInput
        t={t}
        name="password"
        label={t("pages.login.inputs.password.label")}
      />
    </FormLayout.FieldGroup>
  );
};
