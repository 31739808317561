import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@nowsta/tempo-ds";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { ControlledTextArea } from "../inputs/controlled";
import * as Presenter from "./NoteModal.presenter";

export type NoteModalData = {
  timecardId: string;
  note: string | null;
  workerName: string;
};

interface Props {
  data: NoteModalData;
  isSubmitting?: boolean;
  onSubmit: (note: string) => void;
}

const schema = yup.object({
  note: yup.string().required("components.noteModal.inputs.note.required"),
});

type FormData = yup.InferType<typeof schema>;

export const NoteModalForm = ({ data, isSubmitting, onSubmit }: Props) => {
  const { t } = useTranslation();
  const noteContext = data.note === null ? "add" : "save";
  const form = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: { note: data.note || "" },
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = form;

  return (
    <Presenter.FormWrapper>
      <FormProvider {...form}>
        <ControlledTextArea
          t={t}
          name="note"
          placeholder={t("components.noteModal.inputs.note.placeholder")}
          sizeUI={{ minH: 86 }}
        />
      </FormProvider>
      <Button
        palette="ui"
        uiStyle="secondary"
        isWorking={isSubmitting}
        onClick={handleSubmit((d) => onSubmit(d.note), console.error)}
      >
        {t("components.noteModal.buttons.submit", {
          context: noteContext,
        })}
      </Button>
    </Presenter.FormWrapper>
  );
};
