import { ComponentType, useEffect } from "react";

import { GenericLoadingScreen } from "@/components/GenericLoadingScreen";
import {
  useSessionActions,
  useSessionSelector,
} from "@/redux/hooks/session.hooks";

const withInit =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) => {
    const { handleInitSession } = useSessionActions();
    const { isInitialized } = useSessionSelector();

    useEffect(() => {
      handleInitSession();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isInitialized) {
      return <GenericLoadingScreen />;
    }

    return <Component {...props} />;
  };

interface Props {
  component: ComponentType;
}

export const InitGuard = ({ component }: Props) => {
  const Component = withInit(component);

  return <Component />;
};
