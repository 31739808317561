import { Trans, useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { NowstaWordMark } from "@/components/NowstaWordMark";
import { getTimeclockDateRange } from "@/helpers/getTimeclockDateRange";
import { useSessionSelector } from "@/redux/hooks/session.hooks";
import { useCurrentUser } from "@/redux/hooks/users.hooks";

import { useNetworkStatus } from "../useNetworkStatus";
import * as Presenter from "./CaptainLayout.presenter";

export const CaptainLayout = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { selectedDate } = useSessionSelector();
  const isOnline = useNetworkStatus();

  return (
    <Presenter.Page>
      <Presenter.Header>
        <Presenter.Logo>
          <NowstaWordMark />
          <h6>{t("components.captainLayout.title")}</h6>
        </Presenter.Logo>
        <Presenter.DateIdWrapper>
          <Presenter.TimeclockId>{user?.id}</Presenter.TimeclockId>
          <Presenter.Date>
            <p>
              <Trans
                t={t}
                i18nKey="components.captainLayout.date"
                components={{ bold: <span /> }}
                values={{
                  today: getTimeclockDateRange(selectedDate).start.toJSDate(),
                }}
                tOptions={{
                  formatParams: {
                    today: {
                      day: "numeric",
                      year: "numeric",
                      month: "long",
                    },
                  },
                }}
              />
            </p>
          </Presenter.Date>
        </Presenter.DateIdWrapper>
      </Presenter.Header>
      {!isOnline && (
        <Presenter.NetworkOffline>
          {t("components.captainLayout.offline")}
        </Presenter.NetworkOffline>
      )}
      <Presenter.Body>
        <Outlet />
      </Presenter.Body>
    </Presenter.Page>
  );
};
