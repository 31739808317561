import { ExclaimationMark, Notifier, SearchInput } from "@nowsta/tempo-ds";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import {
  EditTimecardModal,
  EditTimecardModalState,
} from "@/components/EditTimecardModal";
import { FiltersButton } from "@/components/FiltersButton";
import { NoteModal, NoteModalState } from "@/components/NoteModal";
import { PageFilters } from "@/components/PageFilters";
import { PositionFiltersModal } from "@/components/PositionFiltersModal";
import { ShiftCard } from "@/components/ShiftCard";
import {
  canClockIn,
  canClockOut,
  getBreakTimes,
} from "@/helpers/timecardHelpers";
import { useSessionSelector } from "@/redux/hooks/session.hooks";
import {
  useHaveShiftsFetched,
  useShiftsListLoadingState,
  useShiftsLoadingState,
} from "@/redux/hooks/shifts.hooks";
import { useTimecardsActions } from "@/redux/hooks/timecards.hooks";
import { TimecardWorkerStatusFilter } from "@/types/Timecards";
import { ShiftEvent } from "@/types/WorkerShift";
import { Toast } from "@/types/common";

import * as Presenter from "./Workers.presenter";
import { useClockInGroups } from "./useClockInTimes";
import { useFetchShifts } from "./useFetchShifts";
import { useFilteredShifts } from "./useFilteredShifts";
import { usePollTime } from "./usePollTime";
import { usePositions, usePositionsTotals } from "./usePositions";
import { Shift, useShifts } from "./useShifts";

type SelectedFilters = Record<string, true>;

export const Workers = () => {
  const { t } = useTranslation();
  const { handleEditTimecard } = useTimecardsActions();
  const { selectedDate } = useSessionSelector();
  const [toast, setToast] = useState<Toast>({
    isVisible: false,
    message: "",
  });
  const haveShiftsFetched = useHaveShiftsFetched();
  const shifts = useShifts();
  const [status, setStatus] = useState<TimecardWorkerStatusFilter | null>(null);
  const [noteModal, setNoteModal] = useState<NoteModalState>(null);
  const [editTimecardEditModal, setEditTimecardModal] =
    useState<EditTimecardModalState>(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 200);
  const positions = usePositions();
  const positionsCounts = usePositionsTotals();
  const loadingState = useShiftsLoadingState();
  const listLoadingState = useShiftsListLoadingState();

  const [filtersModal, setFiltersModal] = useState<{
    isOpen: boolean;
    positionIds: SelectedFilters;
    timeGroupIds: SelectedFilters;
  }>({ isOpen: false, positionIds: {}, timeGroupIds: {} });

  const { filteredShifts, statusCounts } = useFilteredShifts({
    search: debouncedSearch,
    positions: filtersModal.positionIds,
    times: filtersModal.timeGroupIds,
    shifts,
    status,
  });

  const pollTime = usePollTime();
  const clockInGroups = useClockInGroups();

  const hasShifts = shifts.length > 0;

  useFetchShifts(pollTime, selectedDate);

  const onEvent = useCallback(
    async (tc: Shift, e: ShiftEvent) => {
      if (e === "addNote") {
        setNoteModal({
          note: tc.shiftEvents.note,
          timecardId: tc.id,
          workerName: tc.worker.name,
        });
      }

      if (e === "editTimecard") {
        setEditTimecardModal({ timecard: tc });
      }

      if (e === "clockIn") {
        if (!canClockIn(tc)) {
          setToast({
            isVisible: true,
            message: "pages.workers.toasts.clockInTooEarly",
            palette: "notice",
          });
          return;
        }
        handleEditTimecard({
          timecardId: tc.id,
          body: { clockInAt: DateTime.now() },
          event: "clockIn",
        });
      }

      if (e === "clockInOnTime") {
        handleEditTimecard({
          timecardId: tc.id,
          body: { clockInAt: tc.shiftDetails.schedule.startTime },
          event: "clockInOnTime",
        });
      }

      if (e === "clockOut") {
        if (!canClockOut(tc)) {
          setToast({
            isVisible: true,
            message: "pages.workers.toasts.clockOutTooEarly",
            palette: "notice",
          });
          return;
        }
        handleEditTimecard({
          timecardId: tc.id,
          body: { clockOutAt: DateTime.now() },
          event: "clockOut",
        });
      }

      if (e === "addBreak") {
        const breakTimes = getBreakTimes(tc);
        if (breakTimes) {
          handleEditTimecard({
            timecardId: tc.id,
            body: {
              workBreaks: [
                {
                  breakClockInAt: breakTimes.start,
                  breakClockOutAt: breakTimes.end,
                },
              ],
            },
            event: "addBreak",
          });
        }
      }
    },
    [handleEditTimecard]
  );

  const openFilters = useCallback(() => {
    setFiltersModal(({ positionIds, timeGroupIds }) => ({
      isOpen: true,
      positionIds,
      timeGroupIds,
    }));
  }, []);

  const closeFilters = useCallback(() => {
    setFiltersModal(({ positionIds, timeGroupIds }) => ({
      isOpen: false,
      positionIds,
      timeGroupIds,
    }));
  }, []);

  const setPositionFilters = useCallback(
    ({
      ids,
      timeGroups,
    }: {
      ids: Record<string, true>;
      timeGroups: Record<string, true>;
    }) => {
      setFiltersModal({
        isOpen: false,
        positionIds: ids,
        timeGroupIds: timeGroups,
      });
    },
    []
  );

  return (
    <div>
      <div>
        <PageFilters
          status={status}
          onStatusChange={setStatus}
          workerCounts={statusCounts}
          isLoading={listLoadingState && !haveShiftsFetched}
        />

        <Presenter.Filters>
          <SearchInput
            placeholder={t("pages.workers.inputs.search.placeholder")}
            value={search}
            onChange={setSearch}
            disabled={!hasShifts}
          />
          <FiltersButton
            onClick={openFilters}
            filterCount={
              Object.keys(filtersModal.positionIds).length +
              Object.keys(filtersModal.timeGroupIds).length
            }
            disabled={!hasShifts}
            isWorking={listLoadingState && !haveShiftsFetched}
          >
            {t("pages.workers.buttons.filters")}
          </FiltersButton>
        </Presenter.Filters>

        {(hasShifts || !haveShiftsFetched) && (
          <Presenter.WorkersList>
            {filteredShifts.map((t, i) => (
              <ShiftCard
                key={`${i}`}
                timecard={t}
                onEvent={onEvent}
                loadingState={loadingState[t.id]}
              />
            ))}
          </Presenter.WorkersList>
        )}

        {!haveShiftsFetched && !hasShifts && listLoadingState && (
          <Presenter.LoadingWrapper>
            <Presenter.LoadingSpinner />
          </Presenter.LoadingWrapper>
        )}

        {!hasShifts && haveShiftsFetched && (
          <Presenter.NoShifts>{t("pages.workers.empty")}</Presenter.NoShifts>
        )}
      </div>

      <NoteModal
        data={noteModal}
        onClose={() => setNoteModal(null)}
        isSubmitting={
          noteModal ? loadingState[noteModal.timecardId]?.addNote : false
        }
        onSubmit={async ({ note, timecardId }) => {
          await handleEditTimecard({
            timecardId,
            body: { note },
            event: "addNote",
          });
          setNoteModal(null);
        }}
      />

      <EditTimecardModal
        data={editTimecardEditModal}
        onClose={() => setEditTimecardModal(null)}
        isSubmitting={
          editTimecardEditModal
            ? loadingState[editTimecardEditModal.timecard.id]?.editTimecard
            : false
        }
        onSubmit={async (id, data) => {
          await handleEditTimecard({
            timecardId: id,
            body: {
              clockInAt: data.clockInAt
                ? DateTime.fromISO(data.clockInAt)
                : data.clockInAt === null
                ? null
                : undefined,
              clockOutAt: data.clockOutAt
                ? DateTime.fromISO(data.clockOutAt)
                : data.clockOutAt === null
                ? null
                : undefined,
              workBreaks: data.workBreaks?.length
                ? [
                    {
                      breakClockInAt: DateTime.fromISO(
                        data.workBreaks[0].breakClockInAt
                      ),
                      breakClockOutAt: DateTime.fromISO(
                        data.workBreaks[0].breakClockOutAt
                      ),
                    },
                  ]
                : [],
            },
            event: "editTimecard",
          });

          setEditTimecardModal(null);
        }}
      />

      <PositionFiltersModal
        isOpen={filtersModal.isOpen}
        selectedTimeIds={filtersModal.timeGroupIds}
        selectedPostionIds={filtersModal.positionIds}
        onApply={setPositionFilters}
        onClose={closeFilters}
        positions={positions}
        positionCounts={positionsCounts}
        timeGroups={clockInGroups}
      />

      {toast.isVisible && (
        <Notifier
          toastConfig={{
            isToast: true,
            isAutoDismiss: true,
            autoDismissDelay: 3000,
            onClose: () => setToast({ isVisible: false, message: "" }),
          }}
          title={t(toast.message)}
          iconLeft={<ExclaimationMark />}
          isBadgeIcon={true}
          palette={toast.palette ?? "critical"}
        />
      )}
    </div>
  );
};
