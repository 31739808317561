import { createSelector } from "@reduxjs/toolkit";
import { useCallback } from "react";

import { SignIn } from "@/types/Auth";
import { FetchClientsQueryParams } from "@/types/Company";
import { UserCompany } from "@/types/User";

import {
  fetchCurrentUser,
  fetchUserCompanies,
  initSession,
  login,
  logout,
  setSelectedCompany,
  setSelectedDate,
} from "../actions/session.actions";
import { useDispatch, useSelector } from "../hooks";
import { RootState } from "../store";

const memoizedSessionSelector = createSelector(
  ({ session }: RootState) => session,
  (session) => {
    const userCompaniesRolesDictionary: Record<string, UserCompany> =
      session.userCompaniesRoles?.reduce((prev, current) => {
        return {
          ...prev,
          [current.companyId]: current,
        };
      }, {});
    return {
      ...session,
      userCompaniesRolesDictionary,
    };
  }
);

export const useSessionSelector = () => useSelector(memoizedSessionSelector);

export const useSessionActions = () => {
  const dispatch = useDispatch();

  const handleLogin = useCallback(
    async (payload: SignIn) => dispatch(login(payload)),
    [dispatch]
  );

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const handleInitSession = useCallback(
    () => dispatch(initSession()),
    [dispatch]
  );

  const handleFetchUserCompanies = useCallback(
    (params: Omit<FetchClientsQueryParams, "timeZone">) =>
      dispatch(fetchUserCompanies(params)),
    [dispatch]
  );

  const handleSetSelectedCompany = useCallback(
    async (companyId: string) => {
      dispatch(setSelectedCompany({ companyId }));
    },
    [dispatch]
  );

  const handleFetchCurrentUser = useCallback(
    () => dispatch(fetchCurrentUser()),
    [dispatch]
  );

  const handleSetSelectedDate = useCallback(
    async (selectedDate: string | null) => {
      dispatch(setSelectedDate({ selectedDate }));
    },
    [dispatch]
  );

  return {
    handleLogin,
    handleLogout,
    handleInitSession,
    handleFetchUserCompanies,
    handleSetSelectedCompany,
    handleFetchCurrentUser,
    handleSetSelectedDate,
  };
};
