import { createSlice } from "@reduxjs/toolkit";

import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "@/helpers/auth";
import {
  getSelectedCompanyId,
  setSelectedCompanyId,
} from "@/helpers/selectedCompany";
import { UserCompany } from "@/types/User";
import { ApiCallState } from "@/types/common";

import {
  fetchCurrentUser,
  fetchUserCompanies,
  initSession,
  login,
  logout,
  setSelectedCompany,
  setSelectedDate,
} from "../actions/session.actions";

export interface SessionState {
  accessToken: string | null;
  refreshToken: string | null;
  savedCompanyId: string | null;
  isLoggingIn: boolean;
  isInitialized: boolean;
  user: ApiCallState<string | null>;
  companies: ApiCallState<string[] | null> & { date: string | null };
  error?: string;
  userCompaniesRoles: UserCompany[];
  selectedCompanyId: string | null;
  selectedDate: string | null;
}

const initialState: SessionState = {
  accessToken: null,
  refreshToken: null,
  savedCompanyId: null,
  isInitialized: false,
  isLoggingIn: false,
  userCompaniesRoles: [],
  selectedCompanyId: null,
  companies: {
    data: null,
    isLoading: false,
    date: null,
  },
  user: {
    data: null,
    isLoading: false,
  },
  selectedDate: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initSession, (state) => {
        state.accessToken = getAccessToken() || null;
        state.refreshToken = getRefreshToken() || null;
        state.savedCompanyId = getSelectedCompanyId() || null;
        state.isInitialized = true;
      })
      .addCase(logout, () => {
        removeAccessToken();
        removeRefreshToken();
        return { ...initialState, isInitialized: true };
      })
      .addCase(setSelectedCompany, (state, { payload }) => {
        const { companyId } = payload;
        if (companyId) {
          setSelectedCompanyId(companyId);
        }
        state.selectedCompanyId = companyId;
      })
      .addCase(setSelectedDate, (state, { payload }) => {
        const { selectedDate } = payload;
        state.selectedDate = selectedDate;
      })
      .addCase(login.pending, (state) => {
        state.isLoggingIn = true;
        state.error = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        setAccessToken(action.payload.accessToken);
        setRefreshToken(action.payload.refreshToken);
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.isLoggingIn = false;
      })
      .addCase(login.rejected, (state, error) => {
        state.error =
          error.error.code === "ERR_NETWORK"
            ? "There seems to be a problem with your internet connection. Please check your connection and try again."
            : "Error logging in. Please make sure you are using valid credentials.";
        state.isLoggingIn = false;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.user.isLoading = true;
        state.user.error = undefined;
        state.userCompaniesRoles = [];
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user.data = action.payload.id;
        if (action.payload.companies) {
          state.userCompaniesRoles = action.payload.companies;
        }
        state.user.isLoading = false;
        state.user.error = undefined;
      })
      .addCase(fetchCurrentUser.rejected, (state) => {
        state.user.error = "Error fetching user";
        state.user.isLoading = false;
        state.user.data = null;
        state.userCompaniesRoles = [];
      })
      .addCase(fetchUserCompanies.fulfilled, (state, action) => {
        state.companies.data = action.payload.companies.map(({ id }) => id);
        state.companies.date = action.payload.date;
        state.companies.isLoading = false;
        state.companies.error = undefined;
      })
      .addCase(fetchUserCompanies.pending, (state) => {
        state.companies.isLoading = true;
        state.companies.error = undefined;
      })
      .addCase(fetchUserCompanies.rejected, (state, action) => {
        state.companies.isLoading = false;
        state.companies.error = action.error.message;
        state.companies.data = null;
      });
  },
});

export const { reducer: sessionReducer } = sessionSlice;
