import { BusyIndicator, color, rem } from "@nowsta/tempo-ds";
import styled from "styled-components";

import { PHONE_QUERY } from "@/helpers/breakpoints";

export const NoShifts = styled.p`
  text-align: center;
  color: ${color.ui122};
  margin-top: ${rem(150)};
  font-weight: bold;
`;

export const WorkersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(150)};
`;

export const LoadingSpinner = styled(BusyIndicator)`
  svg.icon__loading {
    width: ${rem(24)};
    height: ${rem(23)};

    fill: ${color.primary100};
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: ${rem(16)};
  margin-bottom: ${rem(24)};

  > div {
    flex-grow: 1;
  }

  button {
    flex-basis: ${rem(150)};
  }

  ${PHONE_QUERY.start} {
    flex-direction: column;
    gap: ${rem(8)};
    margin-bottom: ${rem(16)};

    > button {
      flex-basis: unset;
      width: 100%;
    }
  }
`;
