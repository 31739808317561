import { rem } from "@nowsta/tempo-ds";
import styled from "styled-components";

import { PHONE_QUERY } from "@/helpers/breakpoints";

export const Main = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)};
  width: 100%;
  height: 100%;

  ${PHONE_QUERY.start} {
    text-align: center;
  }
`;
