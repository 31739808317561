import {
  Button as TempoButton,
  color,
  rem,
  reserved,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled, { css } from "styled-components";

export const FilterCount = styled.div<{ emptyFilters?: boolean }>`
  ${textSize.labelM};
  font-weight: ${textWeight.medium};
  padding: ${rem(2)} ${rem(8)};
  border-radius: ${rem(reserved.corners.small)};
  color: ${({ emptyFilters }) => (emptyFilters ? color.ui100 : "white")};
  background-color: ${({ emptyFilters }) =>
    emptyFilters ? color.ui20 : color.primary100};
`;

export const Button = styled(TempoButton)`
  ${({ palette }) => {
    if (palette === "ui") {
      return css`
        color: ${color.ui122};

        && {
          svg {
            fill: ${color.ui122};
          }
        }
      `;
    }

    return css`
      color: ${color.primary100};

      && {
        svg {
          fill: ${color.primary100};
        }
      }
    `;
  }};
`;
