import { PageNotFound } from "@nowsta/tempo-ds";
import { DateTime } from "luxon";
import { ComponentType, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { GenericLoadingScreen } from "@/components/GenericLoadingScreen";
import {
  useSessionActions,
  useSessionSelector,
} from "@/redux/hooks/session.hooks";
import { useCurrentUser } from "@/redux/hooks/users.hooks";
import { LINKS } from "@/routing/paths";

export const REDIRECT_QUERY_PARAM = "redirect";

// TODO: add listener to storage so that if one tab logs out all open tabs
// are logged out.
const withAuth =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) => {
    const { t } = useTranslation();
    const { date = null } = useParams<"date">();
    const {
      handleFetchCurrentUser,
      handleFetchUserCompanies,
      handleSetSelectedDate,
    } = useSessionActions();
    const { accessToken, user, companies, selectedDate } = useSessionSelector();
    const currentUser = useCurrentUser();
    const location = useLocation();

    useEffect(() => {
      if (user.data || user.isLoading || user.error) {
        return;
      }

      if (accessToken) {
        handleFetchCurrentUser();
      }
    }, [
      accessToken,
      handleFetchCurrentUser,
      user.data,
      user.error,
      user.isLoading,
    ]);

    useEffect(() => {
      if (companies.data || companies.isLoading || companies.error) {
        return;
      }

      if (accessToken && selectedDate === date) {
        handleFetchUserCompanies(date ? { date } : {});
      }
    }, [
      accessToken,
      companies.data,
      companies.error,
      companies.isLoading,
      date,
      handleFetchUserCompanies,
      selectedDate,
    ]);

    useEffect(() => {
      if (date === selectedDate) {
        return;
      }

      if (!date) {
        handleSetSelectedDate(null);
        return;
      }

      if (DateTime.fromFormat(date, "yyyy-MM-dd").isValid) {
        handleSetSelectedDate(date);
        return;
      }
    }, [selectedDate, date, handleSetSelectedDate]);

    if (!accessToken) {
      return (
        <Navigate
          to={{
            pathname: LINKS.login,
            search: `?${REDIRECT_QUERY_PARAM}=${location.pathname}`,
          }}
        />
      );
    }

    if (date && !DateTime.fromFormat(date, "yyyy-MM-dd").isValid) {
      return (
        <PageNotFound description={t("components.authGuard.invalidDate")} />
      );
    }

    if (user.isLoading || companies.isLoading) {
      return <GenericLoadingScreen />;
    }

    if (!currentUser || user.error || !companies.data || companies.error) {
      return <PageNotFound description={t("components.authGuard.404")} />;
    }

    return <Component {...props} />;
  };

interface Props {
  component: ComponentType;
}

export const AuthGuard = ({ component }: Props) => {
  const Component = withAuth(component);

  return <Component />;
};
