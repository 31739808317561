import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { getUpdatedShiftActuals } from "@/helpers/getUpdatedShiftActuals";
import { ShiftEvent } from "@/types/WorkerShift";
import { ApiCallState } from "@/types/common";

import {
  setSelectedCompany,
  setSelectedDate,
} from "../actions/session.actions";
import { DecoratedWorkerShift, fetchListData } from "../actions/shifts.actions";
import { editTimecard } from "../actions/timecards.actions";

export type ShiftLoadingState = Partial<Record<ShiftEvent, true>>;

export interface ShiftsState extends EntityState<DecoratedWorkerShift, string> {
  list: ApiCallState<string[] | null>;
  loading: Partial<Record<string, ShiftLoadingState>>;
}

const shiftsAdapter = createEntityAdapter<DecoratedWorkerShift>({
  sortComparer: (a, b) =>
    a.worker.name
      .toLocaleLowerCase()
      .localeCompare(b.worker.name.toLocaleLowerCase()),
});

export const initialState: ShiftsState = shiftsAdapter.getInitialState({
  list: {
    data: null,
    isLoading: false,
  },
  loading: {},
});

export const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchListData.fulfilled, (state, { payload }) => {
        state.list.isLoading = false;
        state.list.error = undefined;
        state.list.data = payload.shifts.map((s) => s.id);
        shiftsAdapter.setAll(state, payload.shifts);
      })
      .addCase(fetchListData.rejected, (state, { error }) => {
        state.list.isLoading = false;
        state.list.error = error.message;
        state.list.data = null;
      })
      .addCase(fetchListData.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(editTimecard.pending, (state, { meta }) => {
        const tState = state.loading[meta.arg.timecardId];
        if (!tState) {
          state.loading[meta.arg.timecardId] = {
            [meta.arg.event]: true,
          };
          return;
        }

        state.loading[meta.arg.timecardId] = {
          ...tState,
          [meta.arg.event]: true,
        };
      })
      .addCase(editTimecard.rejected, (state, { meta }) => {
        const tState = state.loading[meta.arg.timecardId];
        if (!tState || !tState[meta.arg.event]) {
          return;
        }

        const { [meta.arg.event]: _, ...remainingState } = tState;

        if (Object.keys(remainingState).length <= 0) {
          delete state.loading[meta.arg.timecardId];
          return;
        }

        state.loading[meta.arg.timecardId] = remainingState;
      })
      .addCase(editTimecard.fulfilled, (state, { payload }) => {
        const updatedActuals = getUpdatedShiftActuals(
          state.entities[payload.timecardId],
          payload.body
        );

        if (updatedActuals) {
          shiftsAdapter.updateOne(state, {
            id: payload.timecardId,
            changes: { actuals: updatedActuals },
          });
        }

        const tState = state.loading[payload.timecardId];
        if (!tState || !tState[payload.event]) {
          return;
        }

        const { [payload.event]: _, ...remainingState } = tState;

        if (Object.keys(remainingState).length <= 0) {
          delete state.loading[payload.timecardId];
          return;
        }

        state.loading[payload.timecardId] = remainingState;
      })
      .addCase(setSelectedCompany, () => initialState)
      .addCase(setSelectedDate, () => initialState);
  },
});

export const shiftsAdapterSelectors = shiftsAdapter.getSelectors();

export const { reducer: shiftsReducer } = shiftsSlice;
