import { rem, textSize, textWeight } from "@nowsta/tempo-ds";
import styled from "styled-components";

export const Body = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(8)};

  h6 {
    ${textSize.headlineL};
    font-weight: ${textWeight.semiBold};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  margin-bottom: ${rem(20)};
  flex-grow: 1;
  overflow: hidden;
  padding-left: ${rem(3)};

  label {
    ${textSize.bodyXL};
    font-weight: ${textWeight.regular};

    strong {
      font-weight: ${textWeight.semiBold};
    }
  }
`;
