import { BusyIndicator } from "@nowsta/tempo-ds";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Presenter from "./LoadingMessages.presenter";

export interface LoadingMessagesProps {
  messages?: string[];
  duration?: number;
  className?: string;
}

export const LoadingMessages = React.forwardRef<
  HTMLDivElement,
  LoadingMessagesProps
>(({ messages: propMessages, duration = 3000, className }, ref) => {
  const { t } = useTranslation();
  const [activeMessage, setActiveMessage] = useState(0);
  const messages = propMessages
    ? propMessages
    : (t("components.loadingMessages.defaultMessages", {
        returnObjects: true,
      }) as string[]);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveMessage((index) => (index + 1) % messages.length);
    }, duration);

    return () => clearInterval(timer);
  }, [duration, messages]);

  return (
    <Presenter.Main ref={ref} className={className}>
      <BusyIndicator />
      <Presenter.Message>{messages[activeMessage]}</Presenter.Message>
    </Presenter.Main>
  );
});

LoadingMessages.displayName = "LoadingMessages";
