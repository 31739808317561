import { ApiFunction } from "@/types/Api";
import {
  EditTimecardPathParams,
  EditTimecardRequestBody,
} from "@/types/Timecards";

import { createDefaultClient } from "./shared";

export const timecardClient = createDefaultClient("core");

export const TIMECARD_SERVICE_PATHS = {
  edit: ({ timecardId }: EditTimecardPathParams) => `/timecards/${timecardId}`,
};

type TimecardServicePaths = typeof TIMECARD_SERVICE_PATHS;

export type TimecardService = {
  editTimecard: ApiFunction<
    undefined,
    EditTimecardRequestBody,
    TimecardServicePaths["edit"]
  >;
};

export const timecardService: TimecardService = {
  editTimecard: async ({ requestBody, ...params }) => {
    await timecardClient.patch(
      TIMECARD_SERVICE_PATHS.edit(params),
      requestBody
    );
  },
};
