import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { timecardService } from "@/services/timecard";
import { EditTimecardRequestBody } from "@/types/Timecards";
import { ShiftEvent } from "@/types/WorkerShift";

export interface EditTimecardParams<DateStyle = string> {
  timecardId: string;
  body: EditTimecardRequestBody<DateStyle>;
  event: ShiftEvent;
}

export const editTimecard = createAsyncThunk<
  EditTimecardParams,
  EditTimecardParams<DateTime>
>("timecards/edit", async ({ timecardId, body, event }) => {
  const { clockInAt, clockOutAt, workBreaks, note } = body;
  const actualBody: EditTimecardRequestBody = {
    ...(clockInAt
      ? { clockInAt: clockInAt.toUTC().toISO() as string }
      : clockInAt === null
      ? { clockInAt }
      : {}),
    ...(clockOutAt
      ? { clockOutAt: clockOutAt.toUTC().toISO() as string }
      : clockOutAt === null
      ? { clockOutAt }
      : {}),
    ...(workBreaks && workBreaks.length > 0
      ? {
          workBreaks: [
            {
              breakClockInAt: workBreaks[0].breakClockInAt
                .toUTC()
                .toISO() as string,
              breakClockOutAt: workBreaks[0].breakClockOutAt
                .toUTC()
                .toISO() as string,
            },
          ],
        }
      : workBreaks && workBreaks.length === 0
      ? { workBreaks: [] }
      : {}),
    ...(note ? { note } : {}),
  };
  await timecardService.editTimecard({ timecardId, requestBody: actualBody });
  return {
    timecardId,
    body: actualBody,
    event,
  };
});
