import { PageNotFound } from "@nowsta/tempo-ds";
import { ComponentType, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import { GenericLoadingScreen } from "@/components/GenericLoadingScreen";
import { useCompanySelector } from "@/redux/hooks/companies.hooks";
import {
  useSessionActions,
  useSessionSelector,
} from "@/redux/hooks/session.hooks";
import { LINKS } from "@/routing/paths";

const withCompany =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) => {
    const { t } = useTranslation();
    const { companyId, date = null } = useParams<"companyId" | "date">();
    const { companies, selectedCompanyId } = useSessionSelector();
    const targetCompany = useCompanySelector(companyId || null);
    const { handleSetSelectedCompany } = useSessionActions();

    const targetCompanyId = targetCompany?.id ?? null;

    useEffect(() => {
      if (
        targetCompanyId &&
        companies.data &&
        companies.date === date &&
        companies.data.includes(targetCompanyId)
      ) {
        handleSetSelectedCompany(targetCompanyId);
      }
    }, [
      companies.data,
      companies.date,
      date,
      handleSetSelectedCompany,
      targetCompanyId,
    ]);

    if (!companyId) {
      return <Component {...props} />;
    }

    const isSwitchingCompany =
      selectedCompanyId && companyId !== selectedCompanyId;
    const isSettingCompany =
      targetCompany?.id && selectedCompanyId !== targetCompany.id;

    if (isSwitchingCompany || isSettingCompany) {
      return <GenericLoadingScreen />;
    }

    // Redirect to company selector, if the user somehow has a selectedCompanyId that they don't have access to
    if (
      companies.data &&
      ((selectedCompanyId && !companies.data.includes(selectedCompanyId)) ||
        (companyId && !companies.data.includes(companyId)))
    ) {
      return <Navigate to={LINKS.companySelector} replace />;
    }

    if (!targetCompany) {
      return <PageNotFound description={t("components.companyGuard.404")} />;
    }

    return <Component {...props} />;
  };

interface Props {
  component: ComponentType;
}

export const CompanyGuard = ({ component }: Props) => {
  const Component = withCompany(component);

  return <Component />;
};
