import {
  Button,
  MediumModal,
  TimePicker,
  color,
  mq,
  rem,
  reserved,
} from "@nowsta/tempo-ds";
import styled, { css } from "styled-components";

import { FieldRow } from "../FormLayout";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  > button {
    width: 100%;
  }
`;

export const EditTimecardCustomModal = styled(MediumModal)`
  > div {
    > div {
      ${mq.fromTablet.small} {
        width: ${rem(430)};
      }

      ${mq.fromLaptop.start} {
        width: ${rem(380)};
      }

      > div {
        overflow: visible;

        > div {
          overflow: visible;
        }
      }

      > div:nth-child(2) {
        > div {
          padding-bottom: ${rem(32)};

          > div {
            ${mq.untilTablet.small} {
              max-width: ${rem(380)};
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
`;

export const FormRowFullWidth = styled(FieldRow)`
  width: 100%;
`;

export const FieldSeparator = styled.span`
  bottom: ${rem(12)};
  color: ${color.ui160};
  display: block;
  position: relative;
`;

export const CustomAlignedTimePicker = styled(TimePicker)<{
  $mobileAlignToLeft?: boolean;
  $mobileAlignToTop?: boolean;
}>`
  > div:nth-child(2) {
    > div:nth-child(2),
    > div:nth-child(4) {
      > div {
        ${({ $mobileAlignToLeft }) =>
          $mobileAlignToLeft &&
          css`
            ${mq.untilTablet.small} {
              left: auto;
              right: 0;
              z-index: ${reserved.zStack.modals};
            }
          `};

        ${({ $mobileAlignToTop }) =>
          $mobileAlignToTop &&
          css`
            ${mq.untilTablet.small} {
              top: auto;
              bottom: 100%;
              margin-top: 0;
              margin-bottom: ${rem(8)};
              z-index: ${reserved.zStack.modals};
            }
          `};
      }
    }
  }
`;

export const CustomButton = styled(Button)`
  ${mq.fromTablet.start} {
    ${mq.whileTouch} {
      height: ${rem(46)};
    }
  }

  > div {
    ${mq.untilMobile.small} {
      font-size: 13px;
    }

    ${mq.untilTablet.small} {
      padding-left: ${rem(14)};
      padding-right: ${rem(14)};
    }
  }
`;
