import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios";

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "@/helpers/auth";
import { logger } from "@/helpers/logger";
import { LINKS } from "@/routing/paths";
import { ListRequestParams } from "@/types/Api";

import { AuthService, authService } from "./auth";

const MAX_401_RETRIES = 1;

interface ErrorInterceptorState {
  isRefreshing: boolean;
  refreshPromise: ReturnType<AuthService["refresh"]> | null;
}

export interface AdminUsersRequest extends ListRequestParams {
  getAdmins: boolean;
}

const errorInterceptorState: ErrorInterceptorState = {
  isRefreshing: false,
  refreshPromise: null,
};

const DEFAULT_CLIENT_CONFIG: CreateAxiosDefaults = {
  timeout: 6000,
  headers: {},
  baseURL: `${import.meta.env.VITE_API_URL}`,
};

export interface CompanyIdParam {
  companyId: string;
}

export interface PresignUploadRequest {
  mimeType: string;
}

export interface PresignUploadResponse {
  signedS3UploadUrl: string;
  fileUrl: string;
}

const authTokenInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
  }
  return config;
};

const refreshToken = async () => {
  const { isRefreshing, refreshPromise } = errorInterceptorState;
  if (!isRefreshing || !refreshPromise) {
    errorInterceptorState.isRefreshing = true;
    errorInterceptorState.refreshPromise = authService
      .refresh({
        requestBody: { refreshToken: getRefreshToken() || "" },
      })
      .then((res) => {
        if (res.accessToken) {
          setAccessToken(res.accessToken);
        }
        return res;
      })
      .finally(() => {
        errorInterceptorState.isRefreshing = false;
      });
  }

  return errorInterceptorState.refreshPromise as NonNullable<
    ErrorInterceptorState["refreshPromise"]
  >;
};

const handleError = (error: unknown) => {
  console.error(error);
  location.assign(LINKS.logout);
  errorInterceptorState.isRefreshing = false;
  errorInterceptorState.refreshPromise = null;
};

const apiErrorInterceptor = async (error: AxiosError) => {
  logger.error("App Logger Error", error);
};

const authErrorInterceptor = async (
  client: AxiosInstance,
  error: AxiosError
) => {
  const originalRequest = error.config;
  const retryCount = originalRequest?.retryCount ?? 0;

  if (!originalRequest || error.response?.status !== 401) {
    return Promise.reject(error);
  }

  if (retryCount > MAX_401_RETRIES - 1) {
    handleError("Session expired");
    return Promise.reject(error);
  }

  try {
    const { accessToken } = await refreshToken();
    if (accessToken) {
      client.defaults.headers.Authorization = `Bearer ${accessToken}`;
      originalRequest.retryCount = retryCount + 1;
      return client(originalRequest);
    }
  } catch (error) {
    handleError(error);
    return Promise.reject(error);
  }
};

export const responseErrorInterceptor =
  (client: AxiosInstance) =>
  (error: AxiosError): Promise<AxiosResponse | undefined> => {
    apiErrorInterceptor(error);
    return authErrorInterceptor(client, error);
  };

export const createDefaultClient = (servicePath: string) => {
  const client = axios.create({
    ...DEFAULT_CLIENT_CONFIG,
    baseURL: `${DEFAULT_CLIENT_CONFIG.baseURL}/${servicePath}`,
  });

  client.interceptors.request.use(authTokenInterceptor);
  client.interceptors.response.use(undefined, responseErrorInterceptor(client));

  return client;
};

export const createPublicClient = (servicePath: string) => {
  const client = axios.create({
    ...DEFAULT_CLIENT_CONFIG,
    baseURL: `${DEFAULT_CLIENT_CONFIG.baseURL}/${servicePath}`,
  });

  return client;
};
