import { useTranslation } from "react-i18next";

import { EditTimecardRequestBody } from "@/types/Timecards";

import { EditTimecardCustomModal } from "./EditTimecardModal.presenter";
import {
  EditTimecardModalData,
  EditTimecardModalForm,
} from "./EditTimecardModalForm";

export type EditTimecardModalState = EditTimecardModalData | null;

interface Props {
  data: EditTimecardModalState;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (id: string, data: EditTimecardRequestBody) => void;
}

export const EditTimecardModal = ({
  data,
  isSubmitting,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <EditTimecardCustomModal
      visible={data !== null}
      title={t("components.editTimecardModal.title", {
        workerName: data?.timecard.worker.name,
      })}
      body={
        data === null ? null : (
          <EditTimecardModalForm
            data={data}
            isSubmitting={isSubmitting}
            onSubmit={(formData) => onSubmit(data.timecard.id, formData)}
          />
        )
      }
      onClose={onClose}
    />
  );
};
