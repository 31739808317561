import { DateTime } from "luxon";
import { useCallback } from "react";

import { EditTimecardParams, editTimecard } from "../actions/timecards.actions";
import { useDispatch } from "../hooks";

export const useTimecardsActions = () => {
  const distpatch = useDispatch();

  const handleEditTimecard = useCallback(
    (params: EditTimecardParams<DateTime>) => distpatch(editTimecard(params)),
    [distpatch]
  );

  return {
    handleEditTimecard,
  };
};
