import { generatePath } from "react-router-dom";

interface SegmentWithChildren {
  root: string;
  [s: string]: string | SegmentWithChildren;
}

const generatePaths = <T extends SegmentWithChildren>(
  segments: T,
  parent = ""
): T => {
  const basePath = segments.root;
  const prefix = basePath === "/" ? "" : `${parent}/${basePath}`;
  return Object.entries(segments).reduce((paths, [key, value]) => {
    if (key === "root") {
      return {
        ...paths,
        [key]: prefix || "/",
      };
    }

    if (typeof value === "string") {
      return {
        ...paths,
        [key]: `${prefix}/${value}`,
      };
    }

    return {
      ...paths,
      [key]: generatePaths(value, prefix),
    };
  }, {} as T);
};

const createLinkFn =
  <PathParams extends string>(path: string) =>
  (params: Record<PathParams, string>) =>
    generatePath(path, params);

export const SEGMENTS = {
  root: "/",
  login: "login",
  logout: "logout",
  companySelector: "company-selector",
  companyId: {
    root: ":companyId",
    date: ":date",
  },
};

export const PATHS = generatePaths(SEGMENTS);

export const LINKS = {
  root: PATHS.root,
  logout: PATHS.logout,
  login: PATHS.login,
  companySelector: PATHS.companySelector,
  companyHome: createLinkFn<"companyId">(PATHS.companyId.root),
};
