import {
  Chip,
  color,
  rem,
  reserved,
  textSize,
  textWeight,
} from "@nowsta/tempo-ds";
import styled from "styled-components";

import { PHONE_QUERY, TABLET_QUERY } from "@/helpers/breakpoints";

export const Card = styled.div`
  border: ${rem(1)} solid ${color.ui20};
  border-radius: ${rem(reserved.corners.medium)};
  box-shadow: 0 ${rem(3)} ${rem(6)} 0 #00000008;
`;

export const Top = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${rem(24)} ${rem(16)} ${rem(24)} ${rem(24)};
  border-bottom: ${rem(1)} solid ${color.ui20};

  ${TABLET_QUERY.start} {
    padding: ${rem(12)};
  }

  ${PHONE_QUERY.start} {
    border: 0;
  }
`;

export const Overview = styled.div`
  display: flex;
  gap: ${rem(24)};
  align-items: center;

  ${PHONE_QUERY.start} {
    gap: ${rem(12)};
  }
`;

export const OverviewRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
`;

export const WorkerName = styled.h6`
  ${textSize.headlineXL};
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};

  ${PHONE_QUERY.start} {
    ${textSize.headlineS};
  }
`;

export const Position = styled.p`
  ${textSize.labelL};
  font-weight: ${textWeight.medium};
  color: ${color.ui100};

  ${PHONE_QUERY.start} {
    font-size: ${rem(12)};
    line-height: ${rem(14)};
  }
`;

export const ShiftDetailList = styled.ul`
  display: flex;
  gap: ${rem(12)};
`;

export const Schedule = styled.li`
  ${textSize.labelL};
  font-weight: ${textWeight.semiBold};
  color: ${color.ui160};

  ${PHONE_QUERY.start} {
    ${textSize.labelXS};
    font-weight: ${textWeight.medium};
  }
`;

export const Breaks = styled.li`
  > span {
    ${textSize.labelL};
    font-weight: ${textWeight.medium};
    color: ${color.ui122};

    ${PHONE_QUERY.start} {
      ${textSize.labelXS};
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: ${rem(4)};

  ${TABLET_QUERY.start} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Status = styled(Chip)`
  > div {
    height: ${rem(40)};
    > span {
      > span {
        ${textSize.bodyM};
      }
    }
  }

  ${TABLET_QUERY.start} {
    display: none;
  }
`;

export const Bottom = styled.div`
  padding: ${rem(20)} ${rem(24)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${TABLET_QUERY.start} {
    padding: ${rem(12)};
  }

  ${PHONE_QUERY.start} {
    flex-direction: column;
    justify-content: unset;
    align-items: stretch;
    padding: 0;
  }
`;

export const TimecardDetails = styled.dl<{ isClockedIn: boolean }>`
  display: flex;
  gap: ${rem(8)};
  flex-grow: 1;
  flex-shrink: 1;

  > button {
    display: flex;
    flex-direction: column;

    &.extra-width {
      flex-basis: ${rem(96)};
      flex-shrink: 1;
    }

    > dt {
      font-size: ${rem(12)};
      line-height: ${rem(14)};
      color: ${color.ui60};
      font-weight: ${textWeight.semiBold};
      text-transform: uppercase;
      text-align: left;
    }

    > dd {
      ${textSize.headlineM};
      color: ${color.ui160};
      font-weight: ${textWeight.semiBold};
      text-align: left;
    }
  }

  ${PHONE_QUERY.start} {
    background-color: ${({ isClockedIn }) =>
      isClockedIn ? color.primary05 : color.ui05};
    padding: ${rem(4)} ${rem(4)} ${rem(4)} ${rem(70)};
    gap: ${rem(12)};

    > button {
      &.extra-width {
        flex-basis: initial;
      }

      > dt {
        font-size: ${rem(8)};
        line-height: ${rem(10)};
        color: ${({ isClockedIn }) =>
          isClockedIn ? color.primary100 : color.ui80};
      }

      > dd {
        ${textSize.labelXS};
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${rem(8)};
  justify-content: flex-end;

  &:first-child {
    flex-grow: 1;
  }

  ${PHONE_QUERY.start} {
    border-top: ${rem(1)} solid ${color.ui20};
    padding: ${rem(12)};

    > button {
      flex-grow: 1;
    }
  }
`;
