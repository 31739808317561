import { ApiFunction } from "@/types/Api";
import { Company, GetBatchCompaniesRequestBody } from "@/types/Company";

import { createDefaultClient } from "./shared";

export const companyClient = createDefaultClient("core/companies");

export const COMPANY_SERVICE_PATHS = {
  getBatchCompanies: "/batch",
};

export type CompanyService = {
  getBatchCompanies: ApiFunction<Company[], GetBatchCompaniesRequestBody>;
};

export const companyService: CompanyService = {
  getBatchCompanies: async ({ requestBody }) => {
    const { data } = await companyClient.post(
      COMPANY_SERVICE_PATHS.getBatchCompanies,
      requestBody
    );

    return data;
  },
};
