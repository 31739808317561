import { ButtonProps, Filters } from "@nowsta/tempo-ds";

import * as Presenter from "./FiltersButton.presenter";

interface Props
  extends Omit<
    ButtonProps,
    "iconLeft" | "iconRight" | "palette" | "uiStyle" | "as"
  > {
  filterCount?: number | null;
}

export const FiltersButton = ({ children, filterCount, ...props }: Props) => {
  const filterCountIcon = filterCount ? (
    <Presenter.FilterCount>{filterCount}</Presenter.FilterCount>
  ) : null;

  return (
    <Presenter.Button
      iconLeft={<Filters />}
      iconRight={filterCountIcon}
      palette={filterCount ? "primary" : "ui"}
      uiStyle={filterCount ? "secondary" : "outline"}
      {...props}
    >
      {children}
    </Presenter.Button>
  );
};
