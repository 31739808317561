import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { useSelector } from "@/redux/hooks";
import { shiftsAdapterSelectors } from "@/redux/slices/shifts.slice";
import { RootState } from "@/redux/store";

interface ClockInStatus {
  id: string;
  clockedInCount: number;
  expectedCount: number;
  name: DateTime;
}

const getInitialStatus = ({
  id,
  isBackup,
  isClockedIn,
  name,
}: {
  id: string;
  isClockedIn: boolean;
  isBackup: boolean;
  name: DateTime;
}): ClockInStatus => ({
  id,
  name,
  clockedInCount: isClockedIn ? 1 : 0,
  expectedCount: isBackup ? 0 : 1,
});

const updateStatus = (
  s: ClockInStatus,
  {
    isClockedIn,
    isBackup,
  }: {
    isClockedIn: boolean;
    isBackup: boolean;
  }
): ClockInStatus => ({
  ...s,
  clockedInCount: isClockedIn ? s.clockedInCount + 1 : s.clockedInCount,
  expectedCount: isBackup ? s.expectedCount : s.expectedCount + 1,
});

const memoizedClockInSelector = createSelector(
  [
    ({ shifts }: RootState) => shifts.entities,
    ({ shifts }: RootState) => shifts.ids,
  ],
  (shifts, ids) => {
    const statuses = shiftsAdapterSelectors
      .selectAll({ entities: shifts, ids })
      .reduce<Record<string, ClockInStatus>>((clockInGroup, s) => {
        const clockInId = DateTime.fromISO(s.start).startOf("minute");
        const clockInString = clockInId.toISO() as string;
        const targetPosition = clockInGroup[clockInString];
        const isBackup = s.status === "backup";
        const isClockedIn = !!s.actuals.clockInAt;

        if (!targetPosition) {
          return {
            ...clockInGroup,
            [clockInString]: getInitialStatus({
              id: clockInString,
              name: clockInId,
              isBackup,
              isClockedIn,
            }),
          };
        }

        return {
          ...clockInGroup,
          [clockInString]: updateStatus(targetPosition, {
            isBackup,
            isClockedIn,
          }),
        };
      }, {});

    return Object.values(statuses).sort(({ name: id1 }, { name: id2 }) => {
      if (id1 === id2) {
        return 0;
      }
      return id1 < id2 ? -1 : 1;
    });
  }
);

export const useClockInGroups = () => useSelector(memoizedClockInSelector);
