import { EditTimecardRequestBody } from "@/types/Timecards";
import { WorkerShift } from "@/types/WorkerShift";

export const getUpdatedShiftActuals = (
  s: WorkerShift | undefined | null,
  updates: EditTimecardRequestBody
): WorkerShift["actuals"] | null => {
  if (!s || Object.keys(updates).length <= 0) {
    return null;
  }

  if (updates.clockInAt === null) {
    return {
      clockInAt: null,
      clockOutAt: null,
      note: updates.note ? updates.note : s.actuals.note,
      workBreaks: [],
    };
  }

  return {
    clockInAt: updates.clockInAt ? updates.clockInAt : s.actuals.clockInAt,
    clockOutAt:
      updates.clockOutAt || updates.clockOutAt === null
        ? updates.clockOutAt
        : s.actuals.clockOutAt,
    note: updates.note ? updates.note : s.actuals.note,
    workBreaks: updates.workBreaks ? updates.workBreaks : s.actuals.workBreaks,
  };
};
