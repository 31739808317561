import { Skeleton, ViewTab } from "@nowsta/tempo-ds";
import { useTranslation } from "react-i18next";

import { TimecardWorkerStatusFilter } from "@/types/Timecards";

import * as Presenter from "./PageFilters.presenter";

interface Props {
  status: TimecardWorkerStatusFilter | null;
  workerCounts: Record<TimecardWorkerStatusFilter | "all", number>;
  isLoading?: boolean;
  onStatusChange: (status: TimecardWorkerStatusFilter | null) => void;
}

const STATUS_TRANSLATION_MAP: Record<TimecardWorkerStatusFilter, string> = {
  "not-clocked-in": "notClockedIn",
  "clocked-in": "clockedIn",
  "clocked-out": "clockedOut",
};

const FILTER_OPTIONS = Object.entries(STATUS_TRANSLATION_MAP) as [
  TimecardWorkerStatusFilter,
  string
][];

export const PageFilters = ({
  status,
  workerCounts,
  onStatusChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Presenter.Wrapper>
      <Presenter.TabletWrapper>
        {FILTER_OPTIONS.map(([s, statusTranslation]) => (
          <Presenter.Card
            key={s}
            checked={status === s}
            uiStyle={status === s ? "solid" : "outline"}
            onChange={() => {
              onStatusChange(status === s ? null : s);
            }}
          >
            <Presenter.CardContent $isActive={status === s}>
              <h6>{t(`components.pageFilters.${statusTranslation}`)}</h6>

              {!isLoading ? (
                <p>{workerCounts[s]}</p>
              ) : (
                <Skeleton sizeUI={{ w: 30, h: 30 }} boxUI={{ corners: 15 }} />
              )}
            </Presenter.CardContent>
          </Presenter.Card>
        ))}
      </Presenter.TabletWrapper>

      <Presenter.MobileWrapper>
        <ViewTab
          isActive={status === null}
          onClick={() => onStatusChange(null)}
        >
          {t("components.pageFilters.allWorkers")} ({workerCounts.all})
        </ViewTab>
        {FILTER_OPTIONS.map(([s, statusTranslation]) => (
          <ViewTab
            key={s}
            isActive={s === status}
            onClick={() => onStatusChange(s)}
          >
            {t(`components.pageFilters.${statusTranslation}`)} (
            {workerCounts[s]})
          </ViewTab>
        ))}
      </Presenter.MobileWrapper>
    </Presenter.Wrapper>
  );
};
