import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { baseConfig } from "./baseConfig";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(baseConfig);

export { i18next };
