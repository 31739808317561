import pino, { Logger } from "pino";

class AppLogger {
  devLogger: Logger;

  constructor() {
    this.devLogger = pino({ browser: {} });
  }

  error(message: string, ...optionalParams: unknown[]) {
    if (process.env.NODE_ENV !== "production") {
      this.devLogger.error(message, ...optionalParams);
    }
  }

  warn(message: string, ...optionalParams: unknown[]) {
    if (process.env.NODE_ENV !== "production") {
      this.devLogger.warn(message, ...optionalParams);
    }
  }

  info(message: string, ...optionalParams: unknown[]) {
    if (process.env.NODE_ENV !== "production") {
      this.devLogger.info(message, ...optionalParams);
    }
  }
}

export const logger = new AppLogger();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line no-console
  console.debug("[nowsta] App Logger Initialized.");
}
