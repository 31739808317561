import { Button, CheckboxInput, MediumModal } from "@nowsta/tempo-ds";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { addRemoveFromObject } from "@/helpers/addRemoveFromObject";

import * as Presenter from "./PositionFiltersModal.presenter";

interface Props {
  selectedPostionIds: Record<string, true>;
  selectedTimeIds: Record<string, true>;
  positions: {
    name: string;
    id: string;
    clockedInCount: number;
    expectedCount: number;
    totalCount: number;
  }[];
  positionCounts: Record<string, number>;
  timeGroups: {
    id: string;
    name: DateTime;
    clockedInCount: number;
    expectedCount: number;
  }[];
  isOpen: boolean;
  onApply: (params: {
    ids: Record<string, true>;
    timeGroups: Record<string, true>;
  }) => void;
  onClose: () => void;
}

export const PositionFiltersModal = ({
  isOpen,
  selectedPostionIds,
  selectedTimeIds,
  timeGroups,
  positions,
  positionCounts,
  onApply,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [selectedPositions, setSelectedPositions] =
    useState(selectedPostionIds);
  const [selectedTimes, setSelectedTimes] = useState(selectedTimeIds);

  const togglePosition = useCallback((id: string) => {
    setSelectedPositions((state) => addRemoveFromObject(id, state, true));
  }, []);

  const toggleTime = useCallback((id: string) => {
    setSelectedTimes((state) => addRemoveFromObject(id, state, true));
  }, []);

  return (
    <MediumModal
      visible={isOpen}
      title={t("components.positionFiltersModal.title")}
      body={
        isOpen ? (
          <Presenter.Body>
            <Presenter.Header>
              <h6>{t("components.positionFiltersModal.headers.positions")}</h6>
              <Button
                palette="ui"
                uiStyle="secondary"
                onClick={() => setSelectedPositions({})}
              >
                {t("components.positionFiltersModal.buttons.clear")}
              </Button>
            </Presenter.Header>
            <Presenter.List>
              {positions.map((p) => (
                <CheckboxInput
                  key={p.id}
                  checked={selectedPositions[p.id] || false}
                  onChange={() => togglePosition(p.id)}
                >
                  <Trans
                    t={t}
                    i18nKey="components.positionFiltersModal.positionLabel"
                    components={{ bold: <strong /> }}
                    values={{
                      name: p.name,
                      clockedInCount: p.clockedInCount,
                      totalCount: positionCounts[p.id] || 0,
                    }}
                  />
                </CheckboxInput>
              ))}
            </Presenter.List>
            <Presenter.Header>
              <h6>{t("components.positionFiltersModal.headers.times")}</h6>
              <Button
                palette="ui"
                uiStyle="secondary"
                onClick={() => setSelectedTimes({})}
              >
                {t("components.positionFiltersModal.buttons.clear")}
              </Button>
            </Presenter.Header>
            <Presenter.List>
              {timeGroups.map((p) => (
                <CheckboxInput
                  key={p.id}
                  checked={selectedTimes[p.id] || false}
                  onChange={() => toggleTime(p.id)}
                >
                  <Trans
                    t={t}
                    i18nKey="components.positionFiltersModal.timeLabel"
                    components={{ bold: <strong /> }}
                    values={{
                      time: p.name.toJSDate(),
                      clockedInCount: p.clockedInCount,
                      expectedCount: p.expectedCount,
                    }}
                    tOptions={{
                      formatParams: {
                        time: { hour: "numeric", minute: "numeric" },
                      },
                    }}
                  />
                </CheckboxInput>
              ))}
            </Presenter.List>
            <Button
              onClick={() =>
                onApply({ ids: selectedPositions, timeGroups: selectedTimes })
              }
              sizeUI={{ w: "100%" }}
            >
              {t("components.positionFiltersModal.buttons.apply")}
            </Button>
          </Presenter.Body>
        ) : null
      }
      onClose={() => {
        onClose();
        setSelectedPositions(selectedPostionIds);
        setSelectedTimes(selectedTimeIds);
      }}
    />
  );
};
