import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { Company } from "@/types/Company";

import { fetchUserCompanies } from "../actions/session.actions";
import { fetchListData } from "../actions/shifts.actions";

export interface CompaniesState extends EntityState<Company, string> {}

const companiesAdapter = createEntityAdapter<Company>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: CompaniesState = companiesAdapter.getInitialState();

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserCompanies.fulfilled, (state, { payload }) => {
        companiesAdapter.upsertMany(state, payload.companies);
      })
      .addCase(fetchListData.fulfilled, (state, { payload }) => {
        companiesAdapter.upsertMany(state, payload.companies);
      });
  },
});

export const companiesAdapterSelectors = companiesAdapter.getSelectors();

export const { reducer: companiesReducer } = companiesSlice;
