import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowRight, Button, NowstaLogo } from "@nowsta/tempo-ds";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

import * as FormLayout from "@/components/FormLayout";
import { REDIRECT_QUERY_PARAM } from "@/components/Guards/AuthGuard";
import { LogInFields } from "@/components/LogInFields";
import { EXTERNAL_LINKS } from "@/helpers/externalLinks";
import {
  useSessionActions,
  useSessionSelector,
} from "@/redux/hooks/session.hooks";
import { LINKS } from "@/routing/paths";
import { SignIn } from "@/types/Auth";

import * as Presenter from "./Login.presenter";
import { SIGN_IN_SCHEMA } from "./schema";

export const Login = () => {
  const { t } = useTranslation();
  const { accessToken, isLoggingIn, error } = useSessionSelector();
  const { handleLogin } = useSessionActions();
  const [searchParams] = useSearchParams();

  const methods = useForm<SignIn>({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(SIGN_IN_SCHEMA),
  });
  const { handleSubmit } = methods;

  const redirect = searchParams.get(REDIRECT_QUERY_PARAM);

  if (accessToken && redirect) {
    return <Navigate to={redirect} replace />;
  }

  if (accessToken) {
    return <Navigate to={LINKS.root} replace />;
  }

  return (
    <Presenter.PageContainer>
      <Presenter.PageBanner>
        <NowstaLogo iconFill="white" />
        <span>{t("pages.login.cta")}</span>
      </Presenter.PageBanner>
      <Presenter.PageContent>
        <Presenter.ContentBox>
          <Presenter.LogInContainer>
            <Presenter.LogInHeading>
              <h1>{t("pages.login.title")}</h1>
              <p>{t("pages.login.subtitle")}</p>
            </Presenter.LogInHeading>
            <FormProvider {...methods}>
              <FormLayout.Form
                onSubmit={handleSubmit(handleLogin)}
                sizeUI={{ minW: "100%" }}
                boxUI={{ gap: 16 }}
              >
                <LogInFields />
                <FormLayout.FieldGroup>
                  {error && (
                    <FormLayout.ErrorMessage>{error}</FormLayout.ErrorMessage>
                  )}
                  <Button
                    onClick={handleSubmit(handleLogin)}
                    isWorking={isLoggingIn}
                    iconRight={<ArrowRight />}
                    sizeUI={{ w: "100%" }}
                  >
                    {t("pages.login.buttons.login.label")}
                  </Button>
                  <FormLayout.HiddenSubmit />
                </FormLayout.FieldGroup>
              </FormLayout.Form>
            </FormProvider>
          </Presenter.LogInContainer>
        </Presenter.ContentBox>
        <Presenter.Fineprint>
          <p>
            <Trans
              t={t}
              i18nKey="pages.login.footer"
              components={{ separator: <Presenter.Separator /> }}
              values={{ date: new Date() }}
              tOptions={{
                formatParams: {
                  date: { year: "numeric" },
                },
              }}
            />
          </p>
          <p>
            <a
              href={EXTERNAL_LINKS.privacyPolicy}
              title="Privacy Policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("pages.login.buttons.privacyPolicy.label")}
            </a>
            <Presenter.Separator>|</Presenter.Separator>
            <a
              href={EXTERNAL_LINKS.termsOfService}
              title="Terms of Use"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("pages.login.buttons.termsOfUse.label")}
            </a>
          </p>
        </Presenter.Fineprint>
      </Presenter.PageContent>
    </Presenter.PageContainer>
  );
};
