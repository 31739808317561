import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";

import { useSelector } from "@/redux/hooks";
import { shiftsAdapterSelectors } from "@/redux/slices/shifts.slice";
import { RootState } from "@/redux/store";
import { TimecardWorkerStatusFilter } from "@/types/Timecards";

import { Shift } from "./useShifts";

interface Props {
  shifts: Shift[];
  search: string;
  positions: Partial<Record<string, true>>;
  times: Partial<Record<string, true>>;
  status: TimecardWorkerStatusFilter | null;
}

export interface ShiftStatusCounts
  extends Record<TimecardWorkerStatusFilter, number> {
  all: number;
}

const memoizedCountsSelector = createSelector(
  [
    ({ shifts }: RootState) => shifts.entities,
    ({ shifts }: RootState) => shifts.ids,
  ],
  (shifts, ids) => {
    return shiftsAdapterSelectors
      .selectAll({ entities: shifts, ids })
      .reduce<ShiftStatusCounts>(
        (counts, s) => {
          if (s.actuals.clockOutAt) {
            return { ...counts, "clocked-out": counts["clocked-out"] + 1 };
          }

          if (s.actuals.clockInAt) {
            return { ...counts, "clocked-in": counts["clocked-in"] + 1 };
          }
          return { ...counts, "not-clocked-in": counts["not-clocked-in"] + 1 };
        },
        {
          "clocked-in": 0,
          "not-clocked-in": 0,
          "clocked-out": 0,
          all: ids.length,
        }
      );
  }
);

export const useStatusCountsSelector = () =>
  useSelector(memoizedCountsSelector);

const addToCount = (counts: ShiftStatusCounts, s: Shift): ShiftStatusCounts => {
  const key = s.shiftEvents.status;

  return {
    ...counts,
    [key]: counts[key] + 1,
    all: counts.all + 1,
  };
};

export const useFilteredShifts = ({
  positions,
  search,
  times,
  shifts,
  status,
}: Props) => {
  const unfilteredStatusCounts = useStatusCountsSelector();

  return useMemo(() => {
    const hasPositionFilter = Object.keys(positions).length > 0;
    const hasTimeFilter = Object.keys(times).length > 0;
    if (!search && !status && !hasPositionFilter && !hasTimeFilter) {
      return {
        filteredShifts: shifts,
        statusCounts: unfilteredStatusCounts,
      };
    }

    const sanitized = search.toLocaleLowerCase();

    return shifts.reduce<{
      filteredShifts: Shift[];
      statusCounts: ShiftStatusCounts;
    }>(
      ({ filteredShifts, statusCounts }, s) => {
        const statusMatch = status ? s.shiftEvents.status === status : true;
        const nameMatch = search
          ? s.worker.name.toLocaleLowerCase().includes(sanitized)
          : true;
        const positionMatch = hasPositionFilter
          ? positions[s.shiftDetails.positionId]
          : true;
        const timeMatch = hasTimeFilter
          ? times[s.shiftDetails.shiftTimeFilter]
          : true;

        const newCounts =
          positionMatch && nameMatch && timeMatch
            ? addToCount(statusCounts, s)
            : statusCounts;

        return {
          filteredShifts:
            statusMatch && nameMatch && positionMatch && timeMatch
              ? [...filteredShifts, s]
              : filteredShifts,
          statusCounts: newCounts,
        };
      },
      {
        filteredShifts: [],
        statusCounts: {
          "clocked-in": 0,
          "not-clocked-in": 0,
          "clocked-out": 0,
          all: 0,
        },
      }
    );
  }, [positions, search, shifts, status, times, unfilteredStatusCounts]);
};
