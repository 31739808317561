import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import {
  useSessionActions,
  useSessionSelector,
} from "@/redux/hooks/session.hooks";
import { LINKS } from "@/routing/paths";

export const Logout = () => {
  const { handleLogout } = useSessionActions();
  const { accessToken } = useSessionSelector();

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (accessToken) {
    return null;
  }

  return <Navigate to={LINKS.login} replace />;
};
