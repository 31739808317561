import { createSelector } from "@reduxjs/toolkit";

import { reduceIdsToEntities } from "@/helpers/reduceIdsToEntities";

import { useSelector } from "../hooks";
import { companiesAdapterSelectors } from "../slices/companies.slice";
import { RootState } from "../store";

export const useCompaniesByIdSelector = (companyIds: string[]) => {
  return useSelector(({ companies }) =>
    reduceIdsToEntities(
      companyIds,
      companiesAdapterSelectors.selectEntities(companies),
      "company"
    )
  );
};

const memoizedUserCompaniesSelector = createSelector(
  [
    ({ session }: RootState) => session.companies.data,
    ({ companies }: RootState) => companies.entities,
  ],
  (ids, entities) => {
    if (ids === null) {
      throw Error(
        "Make sure you are using `useUserCompanies` hook within an auth context"
      );
    }
    return reduceIdsToEntities(ids, entities, "company");
  }
);

export const useUserCompanies = () =>
  useSelector(memoizedUserCompaniesSelector);

export const useCompaniesSelector = () => {
  return useSelector(({ companies }) => {
    return {
      companies: companiesAdapterSelectors.selectAll(companies),
      companyEntities: companiesAdapterSelectors.selectEntities(companies),
    };
  });
};

export const useCompanySelector = (companyId: string | null = null) => {
  return useSelector(({ companies }) =>
    companyId
      ? companiesAdapterSelectors.selectById(companies, companyId) ?? null
      : null
  );
};

export const useSelectedCompany = () =>
  useSelector(({ companies, session: { selectedCompanyId } }) => {
    if (!selectedCompanyId) {
      throw new Error(
        "There was no selected company. If you are expecting a selected company in this context, make sure the route is wrapped with the 'Company' guard"
      );
    }

    const company = companiesAdapterSelectors.selectById(
      companies,
      selectedCompanyId
    );

    if (!company) {
      throw new Error(
        `Could not find ${selectedCompanyId} in the list of available companies. If you are expecting a selected company in this context, make sure the route is wrapped with the 'Company' guard`
      );
    }

    return company;
  });
