import { CardToggle, color, rem, textSize, textWeight } from "@nowsta/tempo-ds";
import styled, { css } from "styled-components";

import { PHONE_QUERY } from "@/helpers/breakpoints";

export const Wrapper = styled.div`
  margin-bottom: ${rem(24)};

  ${PHONE_QUERY.start} {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
`;

export const TabletWrapper = styled.div`
  display: flex;
  gap: ${rem(16)};

  ${PHONE_QUERY.start} {
    display: none;
  }
`;

export const Card = styled(CardToggle)`
  width: 100%;
  box-shadow: ${rem(0)} ${rem(3)} ${rem(6)} ${rem(0)} ${color.shadows03};

  > div:nth-child(3) {
    display: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 1;
    `}
`;

export const CardContent = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};

  h6 {
    ${textSize.labelM};
    font-weight: ${textWeight.regular};
    color: ${color.ui122};

    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${color.white100};
        opacity: 0.85;
      `}
  }

  p {
    ${textSize.titleS};
    font-weight: ${textWeight.semiBold};
    color: ${color.ui160};

    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${color.white100};
      `}
  }
`;

export const MobileWrapper = styled.div`
  position: relative;
  display: flex;
  gap: ${rem(20)};
  max-width: calc(100% + ${rem(32)});
  overflow: auto;
  background-color: ${color.ui05};
  margin: ${rem(0)} ${rem(-16)};
  padding: ${rem(14)} ${rem(16)};

  ${PHONE_QUERY.end} {
    display: none;
  }

  // Overrides for the ViewTab component since it doesn't accept a className
  > button {
    &::after {
      bottom: ${rem(12)};
    }
  }
`;
