import axios from "axios";

import { ApiFunction } from "@/types/Api";
import {
  Refresh,
  RefreshResponse,
  SignIn,
  SignInResponse,
  SignUpResponse,
} from "@/types/Auth";

export const authClient = axios.create({
  timeout: 6000,
  headers: {},
  baseURL: `${import.meta.env.VITE_API_URL}/user-service/auth`,
});

export const AUTH_PATHS = {
  signUp: "/signup",
  signIn: "/signin",
  refresh: "/refresh",
};

export type AuthService = {
  signUp: ApiFunction<SignUpResponse, SignIn>;
  signIn: ApiFunction<SignInResponse, SignIn>;
  refresh: ApiFunction<RefreshResponse, Refresh>;
};

export const authService: AuthService = {
  signUp: async ({ requestBody }) => {
    const { data } = await authClient.post(AUTH_PATHS.signUp, requestBody);

    return data;
  },
  signIn: async ({ requestBody }) => {
    const { data } = await authClient.post(AUTH_PATHS.signIn, requestBody);

    return data;
  },
  refresh: async ({ requestBody }) => {
    const { data } = await authClient.post(AUTH_PATHS.refresh, requestBody);

    return data;
  },
} as const;
