import {
  Briefcase,
  Chip,
  Company as CompanyIcon,
  IconText,
  MapPin,
} from "@nowsta/tempo-ds";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Thumbnail } from "@/components/Thumbnail";
import { LINKS } from "@/routing/paths";
import { Company } from "@/types/Company";

import * as Presenter from "./SelectableCompanyItem.presenter";

export interface BaseCompanyItemProps {
  company: Company;
}

const BaseCompanyItem = ({ company }: BaseCompanyItemProps) => {
  const { t } = useTranslation();

  const { name, city, state, industry, isInternal, isAgency, logoUrl } =
    company;

  return (
    <Presenter.Content>
      <Presenter.DetailsWrapper>
        <Thumbnail name={name} src={logoUrl} />
        <Presenter.TextGroup>
          <Presenter.DemoBadge>
            {isInternal && (
              <Chip uiSize="xxsmall" palette="primary">
                {t("pages.companySelector.demoBadge")}
              </Chip>
            )}
          </Presenter.DemoBadge>
          <Presenter.Heading>{name}</Presenter.Heading>
          <IconText icon={<MapPin />}>
            {city}, {state}
          </IconText>
          <IconText icon={<CompanyIcon />}>{industry}</IconText>
          {isAgency && (
            <IconText color="red" icon={<Briefcase />}>
              {t("pages.companySelector.agencyLabel")}
            </IconText>
          )}
        </Presenter.TextGroup>
      </Presenter.DetailsWrapper>
    </Presenter.Content>
  );
};

export const SelectableCompanyItem = ({ company }: BaseCompanyItemProps) => {
  const { id, name } = company;
  return (
    <Presenter.Wrapper>
      <Presenter.Link
        to={LINKS.companyHome({ companyId: id })}
        aria-label={name}
      >
        <BaseCompanyItem company={company} />
      </Presenter.Link>
    </Presenter.Wrapper>
  );
};

export interface CompanyItemProps {
  company: Company;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CompanyItem = ({ company, onClick }: CompanyItemProps) => {
  return (
    <Presenter.Wrapper as={"button"} onClick={onClick}>
      <BaseCompanyItem company={company} />
    </Presenter.Wrapper>
  );
};
