import { extractInitials } from "@/helpers/extractInitials";

import * as Presenter from "./AvatarWithSource.presenter";

interface ImageDetails {
  name: string;
  imgUrl?: string | null;
  size: { width: number; height: number };
}

interface Props extends Omit<ImageDetails, "size"> {
  source: Omit<ImageDetails, "size">;
}

export const getResizedImgSrc = (
  src?: string,
  size?: { width: number; height: number }
) => {
  if (!src || !size || !import.meta.env.VITE_DYNAMIC_IMAGES_BASE_URL)
    return src;

  const s3Url = new URL(src);
  const bucket = s3Url.host.split(".")[0];
  const key = s3Url.pathname.replace("/", "");

  // If image is not in an N2 bucket, or not in S3 at all, we just return the original
  if (!bucket.startsWith("n2") || !s3Url.host.includes("s3")) {
    return src;
  }

  const stringifiedImageRequest = JSON.stringify({
    bucket,
    key,
    edits: {
      // Supports various other options too.
      // For more info, see:
      //  - https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/create-and-use-image-requests.html#dynamically-resize-photos
      //  - https://sharp.pixelplumbing.com/api-resize
      resize: {
        ...size,
        fit: "cover",
      },
    },
  });

  const encodedImageRequest = btoa(stringifiedImageRequest);

  return `${
    import.meta.env.VITE_DYNAMIC_IMAGES_BASE_URL
  }/${encodedImageRequest}`;
};

export const ImgOrText = ({ name, imgUrl, size }: ImageDetails) => {
  if (imgUrl && import.meta.env.VITE_DYNAMIC_IMAGES_BASE_URL) {
    return (
      <img src={getResizedImgSrc(imgUrl, size)} alt={name} loading="lazy" />
    );
  }

  return <div>{extractInitials(name)}</div>;
};

export const AvatarWithSource = ({ name, source, imgUrl }: Props) => {
  return (
    <Presenter.Wrapper>
      <Presenter.MainImage>
        <ImgOrText
          name={name}
          imgUrl={imgUrl}
          size={{ width: 64, height: 64 }}
        />
      </Presenter.MainImage>

      <Presenter.SourceImage>
        <ImgOrText
          name={source.name}
          imgUrl={source.imgUrl}
          size={{ width: 24, height: 24 }}
        />
      </Presenter.SourceImage>
    </Presenter.Wrapper>
  );
};
