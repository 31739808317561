export const extractInitials = (
  value = "",
  capitalize = true,
  separator = " "
) => {
  return value
    .replaceAll(/[^a-zA-Z0-9\s]/g, "")
    .split(separator)
    .reduce((initials, chunk) => {
      const firstChar = chunk.charAt(0);
      return (
        initials + (capitalize ? firstChar.toLocaleUpperCase() : firstChar)
      );
    }, "");
};
